
import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { axiosPrivate } from "../axios";
import { Link } from "react-router-dom";

const LeagueInvite = (props) => {


    const league_id = props.league_id;
    const [isSending,setIsSending] = useState(false);
    const [isSent,setIsSent] = useState(false);
    const [isErr,setIsErr] = useState(false);

    const sendInvite = async(e) => {

      console.log(e);

      setIsSent(false);
      setIsSending(true);

      const sendData = {
        email: e.email,
        league_id: league_id
      }

      try {

        await axiosPrivate.post("/email/leagueInvite",sendData);

        setIsErr(false);
        setIsSent(true);

      }
      catch (err) {
        setIsErr(true);

      }

      setIsSending(false);


    }



      return (
        <div>
            <p>Send this link to your friends to have them join this league easily: <Link to={"/league/join/"+league_id} >{"www.spoxmarket.com/league/join/" + String(league_id)}</Link>
              <br></br>Or send an email to them directly:
            </p>
            {!isSending &&
              <Form onFinish={sendInvite}>
                <Form.Item label="E-Mail" name="email" rules={[{ required: true, message: "Enter the email of the person you want to invite." }]}>
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button  type="primary" htmlType="submit" >Send Invite</Button>
                </Form.Item>
              </Form>
            }
            {isSending &&
              <p>Sending Invite</p>
            }
            {!isSending && isSent && !isErr &&
              <p>Invite Sent!</p>
            }
            {!isSending && isErr &&
              <p>Error sending invite, please try again later.</p>
            }

        </div>
      )

}


export default LeagueInvite;