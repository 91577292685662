import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Input } from "antd";
import '../Global.css';
import { sportLeagueMap } from "../CONSTANTS.js";

const PortfolioSettings = (props) => {

    const axiosPrivate = useAxiosPrivate();

    const [isLoading, setIsLoading] = useState(false);
    const [portName,setPortName] = useState("");

    const [leagueTimeframe, setLeagueTimeframe] = useState("2025");
    const [leagueSport,setLeagueSport] = useState("");
    const [leagueFormat,setLeagueFormat] = useState(-1);
    const [leagueInseason,setLeagueInseason] = useState("Yes");

    const [portViewable,setPortViewable] = useState("Yes");

    //port only settings
    const [leagueBudget,setLeagueBudget] = useState(0);
    const [leagueShorting,setLeagueShorting] = useState("");
    //const [leagueTransType,setLeagueTransType] = useState(0);
    const [leagueTransTypeString,setLeagueTransTypeString] = useState("");
    //const [leagueTransAmnt,setLeagueTransAmnt] = useState(0.0);

    //roster only settings
    const [maxSharesPerTeam,setMaxSharesPerTeam] = useState("No");
    const [playoffMultipliers,setPlayoffMultipliers] = useState("No");
    const [rosterData,setRosterData] = useState(null);


    const [changeName,setChangeName] = useState(false);
    const [changeNameSuccess,setChangeNameSuccess] = useState(false);
    const [nameErrMsg,setNameErrMsg] = useState("");
    const [nameErr,setNameErr] = useState(false);
    const [changeViewable,setChangeViewable] = useState(false);
;
    const fetchData = useCallback( async() => {
        setIsLoading(true);
        const dataSend = {port_id:props.port_id};
        console.log(dataSend);
        try {
            const response = await axiosPrivate.post('/portfolio/getSettings',dataSend)

            const settings = response.data[0];

            console.log("get port settings data response");
            console.log(response.data); //debugger
 
            //setIsLoading(false);
            setPortName(settings.port_name);
            setLeagueSport(sportLeagueMap[settings.sport_league_id]);
            if (settings.timeframe<0){
                setLeagueTimeframe(String(settings.timeframe*-1));
            }
            else {
                setLeagueTimeframe(settings.timeframe_name);
            }

            if (settings.inseason){
                setLeagueInseason("Yes");
            }
            else {
                setLeagueInseason("No");
            }

            const lg_format = settings.lg_format;
            setLeagueFormat(lg_format);

            if (lg_format===0){
                setLeagueBudget(settings.budget)
                //setLeagueTransType(settings.trans_cost_type);

                //setLeagueTransType(settings.trans_cost_type);
                //setLeagueTransAmnt(settings.transaction_cost);
                //setLeagueTransPercent(settings.transaction_cost_percent);
                //setLeagueTransPershare(settings.transaction_cost_pershare);
                var transCostString = '';
                let first = false;
                if (settings.trans_cost_type===1){
                    transCostString += "Global: "
                }
                if (Number(settings.transaction_cost)>0.0){
                    if (first){
                        transCostString += " + "
                    }
                    else {
                        first = true;
                    }
                    transCostString += ("$" + Number(settings.transaction_cost).toFixed(2))
                }
                if (Number(settings.transaction_cost_percent)>0.0){
                    if (first){
                        transCostString += " + "
                    }
                    else {
                        first = true;
                    }
                    transCostString += (Number(settings.transaction_cost_percent).toFixed(2) + "%")
                }
                if (Number(settings.transaction_cost_pershare)>0.0){
                    if (first){
                        transCostString += " + "
                    }
                    else {
                        first = true;
                    }
                    transCostString += (Number(settings.transaction_cost_pershare).toFixed(2) + " per Share.")
                }
                if (!first){
                    transCostString = "Free";
                }
                setLeagueTransTypeString(transCostString);


                if (settings.shorting){
                    setLeagueShorting("Allowed");
                }
                else {
                    setLeagueShorting("Blocked")
                }
                if (settings.viewable){
                    setPortViewable("Yes");
                }
                else {
                    setPortViewable("No");
                }

            }
            else if (lg_format===1){

                setMaxSharesPerTeam("No")
                if (settings.maxsharesperteam){
                    const maxteamshares = settings.maxsharesperteam;
                    if (maxteamshares===1){
                        setMaxSharesPerTeam("Yes")
                    }
                    else if (maxteamshares>1){
                        setMaxSharesPerTeam(String(maxteamshares))
                    }
                }

                setPlayoffMultipliers(settings.playoff_multipliers ?  "Yes" : "No");

                const response2 = await axiosPrivate.post('/portfolio/getRosterSettings',dataSend);

                console.log(response2.data)
                const roster_data = new Map();

                for (const pos of response2.data){
                    roster_data.set(pos.position_id,pos.quantity);
                }

                console.log(roster_data)
                setRosterData(roster_data);

            }

            setIsLoading(false);
        }
        catch (err){

        }
      }, [axiosPrivate,props.port_id])
    
      useEffect( () => {
        fetchData();
      }, [fetchData]);

      const changeNameOn = () => {
        setChangeName(true);
        setChangeNameSuccess(false);
      }

      const updatePortName = async(e) => {
        console.log(e);
        const data = {
            new_portname: e.newname,
            port_id: props.port_id
        }

        console.log(data);

        try {
            await axiosPrivate.post('/portfolio/updateName',data);
            setChangeNameSuccess(true);
            await fetchData();
        }
        catch (err) {
            if (err.response?.data?.message){
                console.log(err.response?.data?.message);
                setNameErrMsg("Portfolio Name Update Failed: " + err.response?.data?.message);
              }
              else {
                setNameErrMsg("Portfolio Name Update Failed: " + err.message);
              }
              setNameErr(true);
        }
      }

      const changePrivacy = () => {
        setChangeViewable(true);
      }

      const updatePortViewable = async(e) => {
        console.log(e);
        var currViewable;
        if (portViewable==='Yes'){
            currViewable = true;
        }
        else {
            currViewable = false;
        }
        const data = {
            viewable: !currViewable,
            port_id: props.port_id
        }

        console.log(data);

        try {
            await axiosPrivate.put('/portfolio/updatePrivacy',data);
            setChangeViewable(false);
            await fetchData();
        }
        catch (err) {
            if (err.response?.data?.message){
                console.log(err.response?.data?.message);
              }
              else {
                console.log(err.message);
              }
              setChangeViewable(false);
              await fetchData();
        }
      }


      return (
        <span>
        {!isLoading &&
        <div>
            <br></br>Portfolio Name: {portName}
            <div class='divider'/> <Button size="small" onClick={changeNameOn}>Change Name</Button>
            <br></br> Sport: {leagueSport}
            <br></br> Timeframe: {leagueTimeframe}
            <br></br><br></br>

                {leagueFormat===0 && <div> <b>Portfolio Format Settings:</b>
                    <br></br>Starting Budget: ${leagueBudget}
                    <br></br>Transaction Cost: {leagueTransTypeString}
                    <br></br>Shorting: {leagueShorting}
                    <br></br>In-Season Trading: {leagueInseason}
                    <br></br>Portfolio Viewable: {portViewable} <div class='divider'/> <Button size="small" onClick={changePrivacy}>Change</Button>
                </div>}

                {leagueFormat===1 &&
                    <div>
                        <b>Roster Format Settings:</b>
                        <br></br>Playoff Multipliers Active: {playoffMultipliers}
                        <br></br>Restricted shares per {leagueSport} Team: {maxSharesPerTeam}
                        <br></br>In-Season Roster Changes: {leagueInseason}
                        <br></br>Roster:
                        <br></br>    QB:   {rosterData.get(2)}
                        <br></br>    RB:   {rosterData.get(3)}
                        <br></br>    WR:   {rosterData.get(4)}
                        <br></br>    TE:   {rosterData.get(5)}
                        <br></br>    K:    {rosterData.get(6)}
                        <br></br>    TEAM: {rosterData.get(1)}
                        <br></br>
                        <br></br>Scoring:
                        <br></br>    Yards per Passing Point: 25
                        <br></br>    Points per Passing TD: 4
                        <br></br>    Points per Interception: -2
                        <br></br>    Yards per Rushing Point: 8
                        <br></br>    Points per Rushing TD: 6
                        <br></br>    Points per Reception: 1
                        <br></br>    Yards per Recieving Point: 10
                        <br></br>    Points per Recieving TD: 6
                        <br></br>    Points per Fumble Lost: -2
                        <br></br>    Points per Extra Point Made: 1
                        <br></br>    Points per 39 Yards or Fewer Field Goal Made: 3
                        <br></br>    Points per Field Goal Made 40-49 Yards: 4
                        <br></br>    Points per Field Goal Made 50-59 Yards: 5
                        <br></br>    Points per Field Goal Made 50-59 Yards: 6
                        <br></br>    Points per Extra Point or Field Goal under 40 Yards Missed: -1
                        <br></br>    TEAM Points per Win: 10
                </div>}


            {changeName &&
            <span>
                {!changeNameSuccess && <span>
                    {nameErr && <div>{nameErrMsg}</div>}
                    {!nameErr && <span>
                        <div></div>
                        <div>
                            <Form onFinish={updatePortName}>
                                <Form.Item name="newname" label="Enter New Portfolio Name">
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" >Update Name</Button>
                                </Form.Item> 
                            </Form>
                        </div>
                    </span>}
                </span>}
                {changeNameSuccess && <div> Name Successfully Updated!</div>}
            </span>}

            {changeViewable && <span>
                <Button onClick={updatePortViewable}>Confirm Change to Viewable</Button>
                    </span> }


        </div>}
        {isLoading && <div> Retrieving Portfolio Settings</div>}
        </span>

      )

}


export default PortfolioSettings;