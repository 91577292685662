

import PortTable from "./PortTable"
import PortLeagues from "./PortLeagues";
import React, { useState } from "react";
import PortRoster from "./PortRoster";


const PortfolioHome = (props) => {

    const [isStockView,setIsStockView] = useState(false);

    return (
        <span>
            {props.portInfo.port_format===0
                ? <span>{!isStockView && <p>Stocks in this Portfolio:</p>}<PortTable setChartView={setIsStockView} sell={false} portInfo={props.portInfo} port_id={props.portInfo.port_id} mine={true} from={"Port"}/></span>
                : <span><p>Roster:</p><PortRoster setChartView={setIsStockView} portInfo={props.portInfo} port_id={props.portInfo.port_id} mine={true} from={"Port"}/></span>}
            {!isStockView && <PortLeagues port_id={props.portInfo.port_id} />}
        </span>
    )

}


export default PortfolioHome