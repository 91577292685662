import {Form, Input, Space, Table, Button, Select, Radio} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useCallback, useContext } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useParams } from "react-router-dom";
import useGetMyPortfolios from "../hooks/useGetMyPortfolios.js";
import AuthContext from '../contexts/Authentication.js';
import { Link } from "react-router-dom";
import MustBeSignedInPage from "../RouterHelpers/MustBeSignedInPage.js";
import { useMediaQuery } from "react-responsive";
import { leagueFormatMap } from "../CONSTANTS.js";

const JoinLeaguePage = (props) => {


  const axiosPrivate = useAxiosPrivate();
  const getMyPortfolios = useGetMyPortfolios();

  const isLarge = useMediaQuery({minWidth:900});
  //const isMobile = useMediaQuery({maxWidth:500});
  const isXSmall = useMediaQuery({maxWidth:575});

  const {auth} = useContext(AuthContext);

  if (!auth?.user){
    console.log("User not logged in")
  }

  const params = useParams();
  const id = params.id;
  //console.log(params);


  const [isSubmitting,setIsSubmitting] = useState(false);
  //const [isJoinSubmitting,setIsJoinSubmitting] = useState(false);
  const [isCompleted,setIsCompleted] = useState(false);
  const [isJoined,setIsJoined] = useState(false);
  const [isPrivate,setIsPrivate] = useState(false);
  const [errMsg,setErrMsg] = useState(null);

  const [tableData,setTableData] = useState([{}]);
  const [leagueRecord,setLeagueRecord] = useState({});

  const [portNames,setPortNames] = useState([]);

  const [portStatus,setportStatus] = useState("New");

  const [tablePagination,setTablePagination] = useState({current:1, pageSize:50});

  //const [searchText, setSearchText] = useState('');

  const setLeagueResults = (leagueData) => {

    var data = [];
    console.log(leagueData);
    for (let i=0; i<leagueData.length; ++i){
      var obj;
      var record = leagueData[i];

      var privacy = "No";
      if (record.private_lg){
        privacy = "Yes";
      }

      const transactionType = record.trans_cost_type;
      var transactionTypeString = "Global";
      if (transactionType===0){
        transactionTypeString="Free";
      }
      else if (transactionType===2){
        transactionTypeString="Trade %";
      }
      else if (transactionType===3){
        transactionTypeString="Flat Fee";
      }
      else if (transactionType===4){
        transactionTypeString="Per Share";
      }

      obj = {
        name: record.league_name,
        key: record.league_id,
        private: privacy,
        owner: record.username,
        league_id: record.league_id,
        budget: record.budget,
        trans_type: transactionTypeString,
        lg_format: leagueFormatMap[record.lg_format],
        tf_name: record.timeframe_name
      }
      data.push(obj)
    }
    console.log(data)
    setTableData(data);
  }

  const getTopLeagues = useCallback( async() => {
    const response = await axiosPrivate.get('/league/top');
    console.log(response);
    setLeagueResults(response.data);
    setIsCompleted(true);
  },[axiosPrivate])


  const getSpecificLeague = useCallback( async(id) => {
    const league_id = {lg_id:id}
    const response = await axiosPrivate.post('/league/get',league_id);
    console.log(response);
    setLeagueResults(response.data);
    setIsCompleted(true);
  },[axiosPrivate])

  useEffect( () => {

    if (auth?.user){
      if (id>0){
        getSpecificLeague(id);
      }
      else {
        getTopLeagues();
      }
    }
  }, [getTopLeagues,getSpecificLeague,id,auth.user]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    //setSearchText(selectedKeys[0]);
    //setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    //setSearchText('');
  };

  const getOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });
  

  const columns = [
    {
      title: "League Name",
      dataIndex: "name",
      key: "name",
      width: '25%'
    },
    {
      title: "Private",
      dataIndex: "private",
      key: "private",
      width: isLarge ? '8%' : '17%'
    },
    {
      title: "Budget",
      responsive: ['md'],
      dataIndex: "budget",
      key: "budget",
      width: '10%'

    },
    {
      title: "Format",
      responsive: ['md'],
      dataIndex: "lg_format",
      key: "lg_format",
      width: '10%'
    },
    {
      title: "Season",
      responsive: ['md'],
      dataIndex: "tf_name",
      key: "tf_name",
      width: '10%'
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      ...getOwnerSearchProps("owner")
    },
    {
      title: isLarge ? "Join League" : "Join",
      dataIndex: "key",
      key: "key",
      render : (text,record) => (
        <Button onClick={(e) => {selectLeague(record)}} >{isLarge ? "Select League" : "Join"}</Button>
      )
    }
  ]

 /* const updatePagination = (newPageSize) => {
    const oldPagination = tablePagination;
    console.log(oldPagination);
    const oldSize = oldPagination.pageSize;
    const oldCurrPage = oldPagination.current;
    console.log("old page size is " + oldSize);
    console.log("old current page is " + oldCurrPage);
    console.log("new page size is " + newPageSize);
    const newCurrPage = Math.ceil(((oldCurrPage-1)*oldSize+1)/newPageSize);
    console.log("new current page is " + newCurrPage);
    setTablePagination({...tablePagination,current:newCurrPage,pageSize:newPageSize});
  } */

  const selectLeague = async(record) => {
    console.log(record);
    setIsPrivate(record.private==="Yes")

    setLeagueRecord(record);

    const myPorts = await getMyPortfolios();
    console.log(myPorts);
    const portOptions = myPorts.map( (port) => (
      {
        value: port.port_id,
        label: port.port_name,
        portInfo: port

      }
    ))
    console.log(portOptions);
    setPortNames(portOptions);


  }


  const joinLeagueClick = async (e,record) => {
    console.log("attempting to join the league");
    console.log(e);
    console.log(record);

    try {
      setErrMsg(null);
      //setIsJoinSubmitting(true);

      if (e?.leaguepass){
        record.leaguepass = e.leaguepass;
      }

      record.portname = e.portname;
      record.port_id=e?.port_id?.value;
      console.log(record);

      const response = await axiosPrivate.post('/league/join',record);
      console.log ("response from server");
      console.log(response);

      console.log("league join successful");
      //setIsJoinSubmitting(false);
      setIsJoined(true);

    }
    catch(err){
      console.log("league join unsuccessful");
      console.log(err.response);
      console.log(err.response?.status);
      if (err.response?.data?.message){
        console.log(err.response?.data?.message);
        setErrMsg("League Join Failed: " + err.response?.data?.message);
      }
      else {
        setErrMsg("League Join Failed: " + err);
      }
      
      //setIsJoinSubmitting(false);
      console.log(err);

    }
  }

  const joinAnotherLeagueClick = (e) => {
    setIsCompleted(false);
    setIsJoined(false);

  }

  const searchLeagueClick = async (e) => {
    console.log("here in league search submit");
    console.log(e);

    try {
      setErrMsg(null);
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/league/search',e);
      setIsCompleted(true);
      console.log(response);
      setLeagueResults(response.data);
      setIsSubmitting(false);
      console.log("league search successful");


    }
    catch(err){
      console.log("league search unsuccesful");
      console.log(err);
      setIsSubmitting(false);
      setErrMsg("League Search Failed:" + err.message);
    }
  }


  const portStatusOnChange = (e) => {
    setportStatus(e.target.value);
  }

  const handleTableChange = (pagination) => {
    console.log("handle table change");
    console.log(pagination);
    setTablePagination(pagination);
  }



  const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }

  const expandableRows = {
    expandedRowRender: (record) => (
      <span>
        {isXSmall ?
          <div>
            Season: {record.tf_name}
            <br></br>Format: {record.lg_format}  Budget: ${record.budget}
            <br></br>Transaction cost type: {record.trans_type}
          </div>
        :
          <div>
            Transaction cost type: {record.trans_type}
          </div>
        }
      </span>
    )
  }


  return (
    <div>
      {!auth?.user ? <MustBeSignedInPage /> :
      <div>
        <Form {...layout}  onFinish={searchLeagueClick}>
          <Form.Item label="League Name" name="leaguename" rules={[{ required: true, message: "League name required." }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Search For League</Button>
          </Form.Item>
        </Form>
        {((isCompleted && !isJoined) &&
          <span>
            <Table
              title = {() => "League Results"}
              dataSource={tableData}
              columns={columns}
              pagination={tablePagination}
              loading={isSubmitting}
              expandable={expandableRows}
              size="middle"
              onChange={handleTableChange}
              scroll={{y:350}}
            />

            {leagueRecord.league_id>0 ? <Form {...layout}  onFinish={(e)=> {joinLeagueClick(e,leagueRecord)}}>

              {isPrivate && <span> Password required for Private League {leagueRecord.name}
                <Form.Item label="League Password:" name="leaguepass" rules={[{ required: true, message: "Password required." }]}>
                  <Input />
                </Form.Item></span>}
              <div>Create New Portfolio for League: {leagueRecord.name}?</div>
              <Radio.Group value={portStatus} onChange={(portStatusOnChange)}>
                <Radio value="New">Yes</Radio>
                <Radio value="Old">No</Radio>
              </Radio.Group>
              {portStatus==="New"
              ?
                <Form.Item label="New Portfolio Name" name="portname" rules={[{ required: true, message: "Portfolio name required." }]}>
                  <Input />
                </Form.Item>
              :
                <Form.Item label="Existing Portfolio" name="port_id" rules={[{ required: true, message: "Must select a portfolio." }]}>
                  <Select labelInValue={true} placeholder="Please choose a portfolio" options={portNames}>
                  </Select>
                </Form.Item>
              }

              <Form.Item>
                <Button type="primary" htmlType="submit">Join League</Button>
              </Form.Item>
            </Form> : <span></span>}
          </span>
        )}
        {leagueRecord.league_id>0 && <div> </ div>}
        {((isJoined) &&
      <div><p>{"League Successfully Joined. Invite others to join with this link: www.spoxmarket.com/league/join/" + String(leagueRecord.league_id)}</p>
                    <p>Would you like to join another?</p>
        <Form>
          <Form.Item>
            <Button  onClick={joinAnotherLeagueClick} >Yes</Button>
          </Form.Item>
        </Form>
        <Form>
          <Form.Item>
          <Link to={"/league/" + leagueRecord.league_id}>
            <Button  >Go To Joined League</Button>
          </Link>
          </Form.Item>
        </Form>
        <Form>
          <Form.Item>
            <Link to="/portfolio/create">
              <Button>Create Portfolio for League</Button>
            </Link>
          </Form.Item>
        </Form>
        </div>
      )}
        {errMsg}
      </div>}
    </div>

  )
}

export default JoinLeaguePage;