import React from "react";
import './LandingPage.css';


const LandingPage = (props) => {



  return (
    <div>
      <section className="hero">
        <h1 className="hero-title">Revolutionize Your Fantasy Football Experience</h1>
        <p className="hero-description">
          Invest, trade, and manage players as stocks. Take control of your fantasy team like never before.
        </p>
        <button className="cta-button">Get Started</button>
        <section className="features">
        <div className="feature">
          <div className="feature-icon">📈</div>
          <h2>Player Trading</h2>
          <p>Maximize your portfolio by buying, selling, and trading players based on their real-world performance.</p>
        </div>
        <div className="feature">
          <div className="feature-icon">🏆</div>
          <h2>Compete & Win</h2>
          <p>Challenge your friends and rise to the top of the leaderboard with your strategic investments.</p>
        </div>
      </section>
      </section>





      </div>

      
  );
}

export default LandingPage;
