import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import React, { useState,} from "react";
import { Switch, Button } from "antd";

const SITEWIDE_NEWS_NOTIFICATION_BIT = 1;
const TRADING_OPEN_NOTIFICATION_BIT = 2;
const WEEKEND_REMINDER_NOTIFICATION_BIT = 3;

const NotificationSettings = (props) => {

    const axiosPrivate = useAxiosPrivate();

    const [savedNotifs,setSavedNotifs] = useState(props.profileData.notifications);
    const [currentNotifs,setCurrentNotifs] = useState(props.profileData.notifications);

    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);

    const [siteNewsNotif,setSiteNewsNotif] = useState(((props.profileData.notifications & (1 << SITEWIDE_NEWS_NOTIFICATION_BIT)) !==0));
    const [tradingOpenNotif,setTradingOpenNotif] = useState(((props.profileData.notifications & (1 << TRADING_OPEN_NOTIFICATION_BIT)) !==0));
    const [weekendReminderNotif,setWeekendReminderNotif] = useState(((props.profileData.notifications & (1 << WEEKEND_REMINDER_NOTIFICATION_BIT)) !==0));

    const changeSiteNewsNotif = (checked) => {
        //console.log("In change site news notif")
        setSuccessMsg("")
        setSiteNewsNotif(checked);
        if (checked){
            setCurrentNotifs(currentNotifs | ((1 << SITEWIDE_NEWS_NOTIFICATION_BIT)));
        }
        else {
            setCurrentNotifs(currentNotifs & ~((1 << SITEWIDE_NEWS_NOTIFICATION_BIT)));
        }
    }

    const changeTradingOpenNotif = (checked) => {
        //console.log("In change trading open notif")
        setSuccessMsg("")
        setTradingOpenNotif(checked);
        if (checked){
            setCurrentNotifs(currentNotifs | ((1 << TRADING_OPEN_NOTIFICATION_BIT)));
        }
        else {
            setCurrentNotifs(currentNotifs & ~((1 << TRADING_OPEN_NOTIFICATION_BIT)));
        }
    }

    const changeWeekendNotif = (checked) => {
        setSuccessMsg("")
        setWeekendReminderNotif(checked);
        if (checked){
            setCurrentNotifs(currentNotifs | ((1 << WEEKEND_REMINDER_NOTIFICATION_BIT)));
        }
        else {
            setCurrentNotifs(currentNotifs & ~((1 << WEEKEND_REMINDER_NOTIFICATION_BIT)));
        }
    }

    const updateUserNotifications = () => {
        const data = {
            notifications: currentNotifs
        }
        try {
            axiosPrivate.post('/user/setNotificationSettings',data);
            setSavedNotifs(currentNotifs);
            setSuccessMsg("Notifications Successfully updated")
            setErrMsg("")
        }
        catch (err) {
            setSuccessMsg("")
            setErrMsg("Error Updating Notification Preferences")
        }

    }




      return (
        <div>
            <div>Notification Settings</div>
            <div>Recieve Site News Updates: <Switch checkedChildren="Yes" unCheckedChildren="No" checked={siteNewsNotif} onChange={changeSiteNewsNotif}></Switch></div>
            <div>Alert When Trading open for Week: <Switch checkedChildren="Yes" unCheckedChildren="No" checked={tradingOpenNotif} onChange={changeTradingOpenNotif}></Switch></div>
            <div>Weekend Reminder to Make Trades: <Switch checkedChildren="Yes" unCheckedChildren="No" checked={weekendReminderNotif} onChange={changeWeekendNotif}></Switch></div>
            <Button type="primary" disabled={savedNotifs===currentNotifs} onClick={updateUserNotifications}>Submit</Button> {errMsg} {successMsg}
        </div>
      )

}


export default NotificationSettings;