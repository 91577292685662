import React, { useState, useEffect, useCallback } from "react";
import { Table, Tag, Button } from "antd";
import "antd/dist/antd.min.css";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import {positionMap, sportLeagueTimeMap} from '../CONSTANTS.js';
import StockPerformance from "../StockPerformance.js";

import { useMediaQuery } from 'react-responsive';
import axios from "../axios.js";


const PortTable = (props) => {

  const isXSmall = useMediaQuery({maxWidth:575});

  const [isLoading, setIsLoading] = useState(false);
  const [tableData,setTableData] = useState([{}]);
  const [isChartView,setIsChartView] = useState(false);
  const [eqID,setEqID] = useState(-1);
  const [stockName,setStockName] = useState("");
  const [subtimeframe,setSubtimeframe] = useState(-1)
  const [week,setWeek] = useState(-1);
  const [portInfo,setPortInfo] = useState({});
  
  const axiosPrivate = useAxiosPrivate();

  const isLarge = useMediaQuery({minWidth:900});

  const updateChartView = (value) => {
    setIsChartView(value);
    if (props.setChartView){
      props.setChartView(value);
    }
  }

  const getEquityHistory = async(prevweek) => {

    console.log(prevweek);
    const dataSend = {
      port_id: portInfo.port_id,
      week: Math.max(prevweek,0)
    }
    //TODO: separate history calls for mine and not mine -- can see purchase price
    //  and gain at that time, perhaps other info
    const res = await axiosPrivate.post('/portfolio/getEquityHistory',dataSend)
    console.log(res)

    const data = [];
    console.log("getportfolio data history response");
    console.log(res.data); //debugger
    for (let i=0; i<res.data.length; ++i){
      var obj;
      var record = res.data[i];
      console.log(record);
      var YTDchange;
      var change;
      var myChange;
      //console.log(record);
      if (record?.prevprice){
        //console.log(record.prevprice)
        change = (100*(Number(record.price)-Number(record.prevprice))/Number(record.prevprice)).toFixed(2);
      }
      else {
        change = null;
      }
      if (record?.origprice){
        YTDchange = (100*(record.price-record.origprice)/Number(record.origprice)).toFixed(2);
      }
      else {
        YTDchange = null;
      }
      if (record?.purchase_price){
        myChange = (100*(record.price-record.purchase_price)/record.purchase_price).toFixed(2);
        if (record.quantity<0){
          myChange = myChange*-1;
        }
      }
      else {
        myChange = null;
      }
      if (record?.player_id){
        obj = {
          name: record.fname + " " + record.lname,
          position: positionMap[record.position_id],
          team: record.city + " " + record.nickname,
          price: record.price,
          ent_id: record.player_id,
          equity_id: record.equity_id,
          numshares: record.quantity,
          worth: (record.price*record.quantity).toFixed(2),
          restricted: record.restricted,
          change: change,
          YTDchange: YTDchange,
          myChange: myChange,
          myprice: record.purchase_price,
          key: record.equity_id,

        };
      }
      else {
        obj = {
          name: record.city + " " + record.nickname,
          position: "TEAM",
          team: record.city + " " + record.nickname,
          price: record.price,
          ent_id: record.team_id,
          equity_id: record.equity_id,
          key: record.equity_id,
          numshares: record.quantity,
          worth: (record.price*record.quantity).toFixed(2),
          restricted: record.restricted,
          change: change,
          YTDchange: YTDchange,
          myChange: myChange,
          myprice: record.purchase_price,

        }
      }
      if (obj.restricted){
        obj.name = obj.name + "*";
      }
      data.push(obj)
    }
    setTableData(data);
    setIsLoading(false);

  }


  const fetchData = useCallback(async () => {

    setIsLoading(true);
    var data = [];

    try {
      var res;
      var timeData;
      var pInfo;
      if (props.from==="Port"){
        console.log(props.portInfo);
        timeData = {
          timeframe: props.portInfo.port_timeframe
        }

        pInfo = {
          port_id:props.port_id,
          timeframe: props.portInfo.port_timeframe,
          sport_league: props.portInfo.port_sport_league,
          sport_league_id: props.portInfo.port_sport_league_id,
          type: props.portInfo.port_type
        }

      }
      else {
        console.log(props.leagueInfo);
        //const sport_league = props.leagueInfo.league_sport_league;
        //const league_type = props.leagueInfo.league_type;
        timeData = {
          timeframe: props.leagueInfo.league_timeframe
        }

        pInfo = {
          port_id:props.port_id,
          timeframe: props.leagueInfo.league_timeframe,
          sport_league: props.leagueInfo.league_sport_league,
          sport_league_id: props.leagueInfo.league_sport_league_id,
          type: props.leagueInfo.league_type
        }
      }

      setPortInfo(pInfo);

      console.log(timeData);
      const timeRes = await axios.post('/getmarket/timeframe',timeData);
      console.log(timeRes);
      const thisweek = timeRes.data[0].subtimeframe;
      setSubtimeframe(thisweek);

      if (props.mine){
        setWeek(thisweek);
        const dataSend = pInfo;
        console.log(dataSend);
        res = await axiosPrivate.post('/portfolio/getDataWithHist',dataSend)
      }
      else {
        // get previous recorded stock hist
        //res = await axiosPrivate
        setWeek(thisweek-1);
        //console.log(timeRes);
        const dataSendNotMine = {
          port_id: props.port_id,
          week: Math.max(thisweek-1,0)
        }
        res = await axiosPrivate.post('/portfolio/getEquityHistory',dataSendNotMine)
        console.log(res)

      }

      console.log("getportfolio data response");
      console.log(res.data); //debugger
      for (let i=0; i<res.data.length; ++i){
        var obj;
        var record = res.data[i];
        console.log(record);
        var YTDchange;
        var change;
        var myChange;
        //console.log(record);
        if (record?.prevprice){
          //console.log(record.prevprice)
          change = (100*(Number(record.price)-Number(record.prevprice))/Number(record.prevprice)).toFixed(2);
        }
        else {
          change = null;
        }
        if (record?.origprice){
          YTDchange = (100*(record.price-record.origprice)/Number(record.origprice)).toFixed(2);
        }
        else {
          YTDchange = null;
        }
        if (record?.purchase_price){
          myChange = (100*(record.price-record.purchase_price)/record.purchase_price).toFixed(2);
          if (record.quantity<0){
            myChange = myChange*-1;
          }
        }
        else {
          myChange = null;
        }
        if (record?.player_id){
          obj = {
            name: record.fname + " " + record.lname,
            position: positionMap[record.position_id],
            team: record.city + " " + record.nickname,
            price: record.price,
            ent_id: record.player_id,
            equity_id: record.equity_id,
            numshares: record.quantity,
            worth: (record.price*record.quantity).toFixed(2),
            restricted: record.restricted,
            change: change,
            YTDchange: YTDchange,
            myChange: myChange,
            myprice: record.purchase_price,
            key: record.equity_id,

          };
        }
        else {
          obj = {
            name: record.city + " " + record.nickname,
            position: "TEAM",
            team: record.city + " " + record.nickname,
            price: record.price,
            ent_id: record.team_id,
            equity_id: record.equity_id,
            key: record.equity_id,
            numshares: record.quantity,
            worth: (record.price*record.quantity).toFixed(2),
            restricted: record.restricted,
            change: change,
            YTDchange: YTDchange,
            myChange: myChange,
            myprice: record.purchase_price,

          }
        }
        if (obj.restricted){
          obj.name = obj.name + "*";
        }
        data.push(obj)
      }
      setTableData(data);
      setIsLoading(false);
    }
    catch (err) {

    }

  }, [axiosPrivate,props.port_id,props.mine,props.portInfo,props.leagueInfo,props.from])

  useEffect( () => {
    fetchData();
  }, [fetchData]); //empty dependency array means function only called once on mount

  const backFunction = () => {
    updateChartView(false);
  }

  const goToStockChart = (e,record) => {
    console.log(record.ent_id);
    setStockName(record.name);
    setEqID(record.equity_id);
    updateChartView(true);
  }


  var theirColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },

    {
      title: "Week " + String(week) + " Price",
      dataIndex: "price",
      key: "price",
      sorter: {
        compare: (a, b) => a.price - b.price
      }
    },

  ]

  var myColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "",
      responsive: ['md'],
      render : (record) => (
        <Button onClick={(e) => {goToStockChart(e,record)}}>See Chart</Button>
      )
    },
    {
      title: "Purchase Price",
      dataIndex: "myprice",
      key: "myprice",
      responsive: ['sm'],
      sorter: {
        compare: (a, b) => a.myprice - b.myprice
      }
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: {
        compare: (a, b) => a.price - b.price
      }
    },
    {
      title: "Number of Shares",
      dataIndex: "numshares",
      key: "numshares",
      responsive: ['sm'],
      sorter: {
        compare: (a, b) => a.numshares - b.numshares
      }
    },
    {
      title: "#",
      dataIndex: "numshares",
      key: "numshares",
      responsive: ['xs'],
      sorter: {
        compare: (a, b) => a.numshares - b.numshares
      }
    },
    {
      title: "Total Worth",
      dataIndex: "worth",
      key: "worth",
      sorter: {
        compare: (a, b) => a.worth - b.worth
      }
    },
    {
      title: "Weekly Change",
      dataIndex: "change",
      key: "change",
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.change - b.change,
      render : (text,record) => (
        record.change ? (record.change>0 ? <Tag color='green'>{text}%</Tag> : (record.change<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      )
    },
    {
      title: "Gain",
      dataIndex: "myChange",
      key: "myChange",
      responsive: ['sm'],
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.myChange - b.myChange,
      render : (text,record) => (
        record.myChange ? (record.myChange>0 ? <Tag color='green'>{text}%</Tag> : (record.myChange<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      )
    },
    {
      title: "YTD Change",
      dataIndex: "YTDchange",
      key: "YTDchange",
      responsive: ['sm'],
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.YTDchange - b.YTDchange,
      render : (text,record) => (
        record.YTDchange ? (record.YTDchange>0 ? <Tag color='green'>{text}%</Tag> : (record.YTDchange<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      )
    },
  ];

  var myColumnsHist = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "",
      responsive: ['md'],
      render : (record) => (
        <Button onClick={(e) => {goToStockChart(e,record)}}>See Chart</Button>
      )
    },
    {
      title: "Week " + week + " Price",
      dataIndex: "price",
      key: "price",
      sorter: {
        compare: (a, b) => a.price - b.price
      }
    },
    {
      title: "Number of Shares",
      dataIndex: "numshares",
      key: "numshares",
      responsive: ['sm'],
      sorter: {
        compare: (a, b) => a.numshares - b.numshares
      }
    },
    {
      title: "#",
      dataIndex: "numshares",
      key: "numshares",
      responsive: ['xs'],
      sorter: {
        compare: (a, b) => a.numshares - b.numshares
      }
    },
    {
      title: "Total Worth",
      dataIndex: "worth",
      key: "worth",
      sorter: {
        compare: (a, b) => a.worth - b.worth
      }
    }
  ];

  var columns;
  if (props.mine && week===subtimeframe){
    columns = myColumns;
    
  }
  else if (props.mine){
    columns = myColumnsHist;
  }
  else {
    columns = theirColumns;
  }

  const changeWeek = (change) => {

    console.log("change week " + change)
    console.log(portInfo)
    console.log(portInfo.sport_league)
    console.log(portInfo.sport_league_id)
    console.log(portInfo.type)

    const minweek = sportLeagueTimeMap[portInfo.sport_league_id][portInfo.type-1][0];
    console.log(minweek)

    const maxweek = sportLeagueTimeMap[portInfo.sport_league_id][portInfo.type-1][1];
    console.log(maxweek)

    const newweek = week + change;
    console.log(newweek)
    if (newweek<0){
      return;
    }
    else if (newweek<minweek){
      return;
    }
    else if (newweek>maxweek){
      return;
    }
    else if (!props.mine && newweek>=subtimeframe){ //other players cant see current roster
      return;
    }
    setIsLoading(true);
    setWeek(week+change);
    console.log(week)
    if (newweek===subtimeframe){
      fetchData();
    }
    else if (newweek<subtimeframe){
      getEquityHistory(newweek);
    }

    setIsLoading(false);

  }


  const expandableRows = {
    expandedRowRender: (record) => (
      <span>
        <div>Purchase Price: ${record.myprice} Gain: {record.myChange ? (record.myChange>0 ? <Tag color='green'>{record.myChange}%</Tag> : (record.myChange<0 ? <Tag color='red'>{record.myChange}%</Tag> : <Tag>{record.myChange}%</Tag>)) : <span>-</span>}</div>
        <div>YTD Change: {record.YTDchange ? (record.YTDchange>0 ? <Tag color='green'>{record.YTDchange}%</Tag> : (record.YTDchange<0 ? <Tag color='red'>{record.YTDchange}%</Tag> : <Tag>{record.YTDchange}%</Tag>)) : <span>-</span>}</div>
        {<div>
          <StockPerformance eqID={record.equity_id} name={record.name} mobile={true}/>
        </div>}
      </span>
    ),
    rowExpandable: (record) => isXSmall
  }

    return (
      <div>
        {!isChartView && <span>
        {!props.mine && <span>
          <div>Showing {props.user}'s {props.portName} portfolio results.</div>

          </span>}
          <Button typ='primary' size='small' onClick={e=>{changeWeek(-1)}} icon={<LeftOutlined />} ></Button>
          <span>NFL Week: {week}</span>
          <Button typ='primary' size='small'onClick={e=>{changeWeek(1)}} icon={<RightOutlined />} ></Button>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={isLoading}
          size= {isLarge ? "large" : "small"}
          expandable={(isXSmall && props.mine && week===subtimeframe) ? expandableRows: null}
        />
        </span>}
        {isChartView && <StockPerformance backFunction={backFunction} eqID={eqID} name={stockName}/>}
      </div>
    );

  }

  export default PortTable