import {Form, Table, Button} from "antd";
import React, { useEffect,useState,useCallback } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { Link } from "react-router-dom";
//import axios from 'axios';

import { sportLeagueMap } from "../CONSTANTS.js";


const PortLeagues = (props) => {

  const axiosPrivate = useAxiosPrivate();
  const [tableData,setTableData] = useState([{}]);

  const [isErr,setIsErr] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);
  //const [isLeagueSelected,setIsLeagueSelected] = useState(false);
  const isLeagueSelected = false;


  const getPortLeagueInfo = useCallback( async() => {
    try {
      setIsSubmitting(true);
      const dataSend = {
        port_id: props.port_id
      }
      const response = await axiosPrivate.post('/portfolio/leagues',dataSend);
      console.log(response);
      var data = [];
      for (let i=0; i<response.data.length; ++i){
        var obj;
        var record = response.data[i];
        obj = {
          league_name: record.league_name,
          lg_id: record.league_id,
          key: record.league_id,
          lg_started: record.lg_started,
          port_started: record.p_started,
          lg_type: record.lg_type,
          lg_format: record.lg_format,
          timeframe: record.timeframe,
          week_start: record.subtimeframe_start,
          week_end: record.subtimeframe_end,
          sport_league: sportLeagueMap[record.sport_league_id]
        }
        data.push(obj)
      }

      console.log(data);
      setIsErr(false);
      setTableData(data);
      setIsSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setIsSubmitting(false);
      return err;
    }
  }, [axiosPrivate,props.port_id])


  useEffect( () => {
    getPortLeagueInfo();
  }, [getPortLeagueInfo]); //empty dependency array means function only called once on mount



  const removePortFromLeague = async(e,record) => {
    console.log("removing portfolio from league");
    //console.log(e);
    console.log(record);
    const data = {
      league_id: record.lg_id,
      port_id: props.port_id
    }

    try {
      await axiosPrivate.put('/portfolio/removeFromLeague',data);

      getPortLeagueInfo();
    }
    catch (err) {

    }
  }

  const columns = [
    {
      title: "League Name",
      dataIndex: "league_name",
      key: "league_name",
      render : (text,record) => (
        <Link to={"/league/" + record.lg_id}>{text}</Link>
      )
    },
    {
      title: "Remove From League",
      render : (text,record) => (
        !(record.lg_started && record.port_started) ? <Button onClick={(e) => {removePortFromLeague(e,record)}} >Remove From League</Button> : <span></span>

      )
    }
  ]

  /*const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }*/


  return (

    <div>
      { !isLeagueSelected &&
      <span>
      {!isErr &&
      <span>
        {tableData.length>0 && 
          <Table
            title = {() => "League Results"}
            dataSource={tableData}
            columns={columns}
            loading={isSubmitting}
            size="middle"
          />
        }
        {tableData.length<=0 && <div>This portfolio is not a part of any Leagues.</div>}
        </span>
      }
      {isErr && <div><p>Failed to retrieve your tables.</p>
      <Form>
        <Form.Item>
          <Button  onClick={getPortLeagueInfo} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
      </span>
    }
    {/*isLeagueSelected  && <LeaguePage />*/}
    </div>
  )
}

export default PortLeagues;