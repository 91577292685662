import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, } from "antd";
import "antd/dist/antd.min.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { NFLteamMap, positionMap } from "../CONSTANTS.js";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import axios from "../axios.js";

import { useMediaQuery } from 'react-responsive';


const PortRoster = (props) => {

  //const isXSmall = useMediaQuery({maxWidth:575});

  const [isLoading, setIsLoading] = useState(false);
  const [tableData,setTableData] = useState([{}]);

  //const subtimeframe = props.portInfo.subtimeframe;

  //const [week,setWeek] = useState(props.mine ? subtimeframe : (subtimeframe-1));

  const timeframe = props.from==="Port" ? props.portInfo.port_timeframe :props.leagueInfo.league_timeframe;
  const [subtimeframe,setSubtimeframe] = useState(-1)
  const [week,setWeek] = useState(-1);

  const [rosterData,setRosterData] = useState([]);

  
  const axiosPrivate = useAxiosPrivate();

  const isLarge = useMediaQuery({minWidth:900});


  const positionSorter = ((a,b)=>{
    if (a.position_id===b.position_id){
      if(a.pos_ref > b.pos_ref){
        return 1;
      }
      return -1;
    }
    else if (a.position_id===1){
      return 1;
    }
    else if (b.position_id===1){
      return -1;
    }
    else if (a.position_id > b.position_id){
      return 1;
    }
    return -1;
  })

  const emptyRosterData = useCallback(() => {

    console.log("empty roster array");
  
    const roster_data = [];
    console.log(roster_data);

    for (const pos of rosterData){
      const pos_id = pos.position_id;
      const pos_name = positionMap[pos_id]
      for (let i =0; i< pos.quantity; i++){
        //console.log(i)
        const obj = {
          position: pos_name,
          pos_ref: pos_name + String(i),
          position_id: pos_id,
          name: "Empty",
          team: "",
          multiplier: "",
          key: pos_name + String(i),
          restricted: false,
          ent_id: -1,
          stats: ""
        }
        //console.log(obj)
        //console.log(roster_data);
        roster_data.push(obj);
        //console.log(roster_data);
      }
    }
    roster_data.sort(positionSorter)
    console.log(roster_data);
    return roster_data;
  },[rosterData])

  const fetchRosterSettings = useCallback( async () => {

    setIsLoading(true);

    const dataSend = {port_id:props.port_id};
    console.log("fetch roster settings");
    try {
      const response2 = await axiosPrivate.post('/portfolio/getRosterSettings',dataSend);

      console.log(response2.data)
      setRosterData(response2.data);
    }

    catch (err) {

    }
  },[axiosPrivate,props.port_id])

  const getRosterHistory = useCallback(async (week) => {

    console.log("getRosterHistory");

    const dataSend = {
      port_id:props.port_id,
      subtimeframe: week,
      timeframe
    };
    setIsLoading(true);

    try {

      //console.log("Getting roster history")
      console.log(dataSend);
      const response = await axiosPrivate.post('/portfolio/getRosterHistory',dataSend)
      console.log(response);


      const roster_data = emptyRosterData();

      if (roster_data.length===0){
        return;
      }
      console.log(roster_data)


      for (let i=0; i<response.data.length; ++i){
        //var obj;
        var record = response.data[i];
        //console.log(record);

        var pos_id = record.position_id;
        if (!record.player_id){
          pos_id = 1;
        }

        for (let j=0; j<roster_data.length; j++){
            if (pos_id===roster_data[j].position_id){
              if (roster_data[j].name==="Empty"){

                //console.log("Found!")
                //console.log(roster_data[j]);

                const locked = record.locked ? "*" : "";
                //console.log(locked)
                //const oldRec = rosterData[j];
                var newObj = {}
                if (record?.player_id){
                  newObj = {
                    name: record.fname + " " + record.lname + locked,
                    position: positionMap[record.position_id],
                    team: record.city + " " + record.nickname,
                    ent_id: record.player_id,
                    restricted: record.locked,
                    key: record.equity_id,
                    multiplier: record.multiplier,
                    dividends: record.dividends_earned,
                    earnings: (record.multiplier*record.dividends_earned).toFixed(2),
                    stats: getStatsString(record)
                  }
                }
                else {
                  newObj = {
                    name: record.city + " " + record.nickname + locked,
                    position: "TEAM",
                    team: record.city + " " + record.nickname,
                    ent_id: record.team_id,
                    key: record.equity_id,
                    restricted: record.locked,
                    multiplier: record.multiplier,
                    dividends: record.dividends_earned,
                    earnings: (record.multiplier*record.dividends_earned).toFixed(2),
                    stats: record.win===1 ? "W" : ""
                  }
                }
                //console.log(newObj);
                roster_data[j] = newObj;
                //console.log(roster_data[j]);
                break;
              }
            }
        }


      }
      console.log(roster_data)
      setTableData(roster_data);


      /*for (let i=0; i<response.data.length; ++i){
        var obj;
        var record = response.data[i];
        if (record?.player_id){
          obj = {
            name: record.fname + " " + record.lname,
            position: positionMap[record.position_id],
            team: record.city + " " + record.nickname,
            ent_id: record.player_id,
            restricted: record.locked,
            key: record.equity_id,
            multiplier: record.multiplier,
            dividends: record.dividends_earned,
            earnings: (record.multiplier*record.dividends_earned).toFixed(2),
            stats: getStatsString(record)

          };
        }
        else {
          obj = {
            name: record.city + " " + record.nickname,
            position: "TEAM",
            team: record.city + " " + record.nickname,
            ent_id: record.team_id,
            key: record.equity_id,
            restricted: record.locked,
            multiplier: record.multiplier,
            dividends: record.dividends_earned,
            earnings: (record.multiplier*record.dividends_earned).toFixed(2),
            stats: record.win===1 ? "W" : ""

          }
        }
        data.push(obj);
      }
      setTableData(data);
      */
    }
    catch (err){

    }
    setIsLoading(false);
  },[axiosPrivate,props.port_id,timeframe,emptyRosterData])


  const getCurrentRoster = useCallback(async () => {

    console.log("getCurrentRoster");
    const dataSend = {
      port_id:props.port_id,
    };

    setIsLoading(true);

    try {
      const roster_data = emptyRosterData();

        if (roster_data.length===0){
          return;
        }
        //console.log(roster_data)
        const res = await axiosPrivate.post('/portfolio/getRoster',dataSend)
        console.log("getportfolio data response");
        console.log(res.data); //debugger
        for (let i=0; i<res.data.length; ++i){
          var record = res.data[i];
          //console.log(record);

          var pos_id = record.position_id;
          if (!record.player_id){
            pos_id = 1;
          }

          for (let j=0; j<roster_data.length; j++){
              if (pos_id===roster_data[j].position_id){
                if (roster_data[j].name==="Empty"){

                  //console.log("Found!")
                  //console.log(roster_data[j]);

                  const locked = record.locked ? "*" : "";
                  //console.log(locked)
                  //const oldRec = rosterData[j];
                  //console.log(record.game_id)
                  var gameString = "";
                  if (record.game_id){
                    const homeTeam = record.team_id===record.home_team;
                    //console.log( (NFLteamMap.get(record.away_team)))
                    //console.log( (homeTeam ? NFLteamMap.get(record.away_team) :  NFLteamMap.get(record.home_team)))
                    gameString = (homeTeam ? "vs. " : "@") + (homeTeam ? NFLteamMap.get(record.away_team) :  NFLteamMap.get(record.home_team))
                    //console.log(gameString)
                    //const dateString =  (new Date(record.start_time)).toLocaleString([],{  year: "numeric", month: "2-digit", day: "2-digit",hour: '2-digit', minute:'2-digit'});
                    console.log(record.start_time);
                    const dateString =  (new Date(record.start_time)).toLocaleDateString([],{  timeZone:"America/New_York", month: "2-digit", day: "2-digit",hour: '2-digit', minute:'2-digit'});
                    //console.log(dateString)
                    gameString += (" " + dateString);
                    //console.log(gameString)
                  }

                  var newObj = {}
                  if (record?.player_id){
                    newObj = {
                      position: positionMap[record.position_id],
                      position_id: record.position_id,
                      name: record.fname + " " + record.lname  + locked,
                      team: record.city + " " + record.nickname,
                      multiplier: record.multiplier,
                      key: record.equity_id,
                      restricted: record.locked,
                      ent_id: record.player_id,
                      game: gameString
                    }
                  }
                  else {
                    newObj = {
                      position: "TEAM",
                      position_id: record.position_id,
                      name: record.city + " " + record.nickname  + locked,
                      team: record.city + " " + record.nickname,
                      multiplier: record.multiplier,
                      key: record.equity_id,
                      restricted: record.locked,
                      ent_id: record.team_id,
                      game: gameString
                    }
                  }
                  //console.log(newObj);
                  roster_data[j] = newObj;
                  //console.log(roster_data[j]);
                  break;
                }
              }
          }

          /*if (record?.player_id){
            obj = {
              name: record.fname + " " + record.lname,
              position: positionMap[record.position_id],
              team: record.city + " " + record.nickname,
              ent_id: record.player_id,
              restricted: record.locked,
              key: record.equity_id,
              multiplier: record.multiplier

            };
          }
          else {
            obj = {
              name: record.city + " " + record.nickname,
              position: "TEAM",
              team: record.city + " " + record.nickname,
              ent_id: record.team_id,
              key: record.equity_id,
              restricted: record.locked,
              multiplier: record.multiplier

            }
          }
          if (obj.restricted){
            obj.name = obj.name + "*";
          }
          */
          //data.push(obj)
        }
        //console.log(roster_data)
        setTableData(roster_data);
    }
    catch (err){

    }
    setIsLoading(false);
  },[axiosPrivate,props.port_id,emptyRosterData])

  const fetchData = useCallback(async (timeframe) => {

    console.log("fetch data");

    setIsLoading(true);

    const timeData = {timeframe}

    try {

      //console.log(timeData);
      const timeRes = await axios.post('/getmarket/timeframe',timeData);
      //console.log(timeRes);
      const thisweek = timeRes.data[0].subtimeframe;
      setSubtimeframe(thisweek);

      if (props.mine && thisweek<=22){
        setWeek(thisweek)
        getCurrentRoster()

        
      }
      else {
        // get previous recorded stock hist
        setWeek(thisweek-1)
        getRosterHistory(thisweek-1);
      }
      setIsLoading(false);
    }
    catch (err) {

    }

  }, [props.mine,getRosterHistory,getCurrentRoster])

  useEffect( () => {

    fetchData(timeframe);
  }, [fetchData,timeframe]);

  useEffect( () => {

    fetchRosterSettings();
  }, [fetchRosterSettings]);

  const currColumns = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position"
    },
    {
      title: "Multiplier",
      dataIndex: "multiplier",
      key: "multiplier",
      hidden: false
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team"
    },
    {
      title: "Next Game",
      dataIndex: "game",
      key: "game"
    }

  ].filter(item=>!item.hidden);

  const histColumns = [
    {
      title: "Position",
      dataIndex: "position",
      key: "position"
    },
    {
      title: "Multiplier",
      dataIndex: "multiplier",
      key: "multiplier"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },

    {
      title: "Team",
      dataIndex: "team",
      key: "team"
    },
    {
      title: "Dividends",
      dataIndex: "earnings",
      key: "dividends"
    },
    {
      title: "Stats",
      dataIndex: "stats",
      key: "stats"
    }

  ];

  const getStatsString = (record) => {
    var string = '';
    var start = false;
    if (record?.PaYards!==0 && record?.PaYards!==null){
      string += record.PaYards + " Pa Yards"
      if (!start) {
        start = true;
      }
    }
    if (record?.PaTD!==0 && record?.PaTD!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.PaTD + " Pa TDs"
    }
    if (record.IntsThrown!==0 && record?.IntsThrown!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.IntsThrown + " INTs"
    }
    if (record.RuYards!==0 && record?.RuYards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RuYards + " Ru Yards"
    }
    if (record.RuTd!==0 && record?.RuTd!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RuTd + " Ru TDs"
    }
    if (record.Fumbles!==0 && record?.Fumbles!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.Fumbles + " Fumb"
    }
    if (record.Receptions!==0 && record?.Receptions!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.Receptions + " Recs"
    }
    if (record.RecYards!==0 && record?.RecYards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RecYards + " Rec Yards"
    }
    if (record.RecTds!==0 && record?.RecTds!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.RecTds + " Rec TDs"
    }
    if (record.TwoPtConv!==0 && record?.TwoPtConv!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.TwoPtConv + " Two Pt"
    }
    if (record.OPIyards!==0 && record?.OPIyards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.OPIyards + " OPI Yards"
    }
    if (record.DPIyards!==0 && record?.DPIyards!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.DPIyards + " DPI Yards"
    }
    if ((record.KRtds + record.PRtds)!==0  && record?.KRtds!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += (record.KRtds + record.PRtds) + " Return TDs"
    }
    if (record.FGAunder40!==0  && record?.FGAunder40!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FGMunder40 + "/" + record.FGAunder40 + " under 40 FG"
    }
    if (record.FG40!==0  && record?.FG40!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FG40 + " 40-49 FGM"
    }
    if (record.FG50!==0  && record?.FG50!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FG50 + " 50-59 FGM"
    }
    if (record.FG60!==0  && record?.FG60!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.FG60 + " 60+ FGM"
    }
    if (record.XPA!==0  && record?.XPA!==null){
      if (start){
        string += ", "
      }
      else {
        start = true;
      }
      string += record.XPM + "/" + record.XPA + " Extra Points"
    }
    return string;
  }

  const changeWeek = (change) => {

    //console.log("change week " + change)

    const newweek = week + change;
    if (newweek<0){
      return;
    }
    else if (newweek<19){
      return;
    }
    else if (newweek>22){
      return;
    }
    else if (!props.mine && newweek>=subtimeframe){ //other players cant see current roster
      return;
    }
    setIsLoading(true);
    setWeek(week+change);
    console.log(week)
    if (newweek===subtimeframe){
      fetchData(timeframe);
    }
    else if (newweek<subtimeframe){
      getRosterHistory(newweek);
    }

    setIsLoading(false);

  }



    return (
      <div>
        <Button typ='primary' size='small' onClick={e=>{changeWeek(-1)}} icon={<LeftOutlined />} ></Button>
          <span>NFL Week: {week}</span>
          <Button typ='primary' size='small'onClick={e=>{changeWeek(1)}} icon={<RightOutlined />} ></Button>
        {<span>
        <Table
          dataSource={tableData}
          columns={week>=subtimeframe ? currColumns: histColumns}
          pagination={false}
          loading={isLoading}
          size= {isLarge ? "large" : "small"}
        />
        </span>}
      </div>
    );

  }

  export default PortRoster