
import LeagueTable from "./LeagueTable";
import React from "react";

const LeagueHome = (props) => {



    return (
        <span>
            <LeagueTable leagueInfo={props.leagueInfo} />
        </span>
    )

}


export default LeagueHome