import React from "react";

import { Menu } from "antd";

import PortBody from "./PortBody.js";
import { sportLeagueMap, sportMap } from "../CONSTANTS.js";
import { useState, useEffect, useCallback } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useParams } from "react-router-dom";


const PortfolioPage = (props) => {

  const params = useParams();
  const id = params.id;

  const axiosPrivate = useAxiosPrivate();

  const [displayCash,setDisplayCash] = useState(false);
  const [cash,setCash] = useState(0);
  const [spendCash,setSpendCash] = useState(0);
  const [value,setValue] = useState(0);
  const [portIsCurrent,setPortIsCurrent] = useState(true);
  const [portName,setPortName] = useState("");
  const [portFormat,setPortFormat] = useState(-1);
  const [portInfo,setPortInfo] = useState({});
  const [isLoading,setIsLoading] = useState(true);

  const getPortData = useCallback(() => {

    //console.log(params)

    const dataSend = {port_id:id};
    console.log(dataSend);
    //TODO need a message for not having access to port
    axiosPrivate.post('/portfolio/myPortfolio',dataSend).then ((res) => {
      console.log(res.data);

      const record = res.data[0];
      console.log(record)
      setPortIsCurrent(record.timeframe>=7)
      setPortName(record.port_name);
      setPortFormat(record.port_format);
      const pInfo = {
        port_name: record.port_name,
        port_id: record.port_id,
        key: record.port_id,
        port_type: record.port_type,
        port_timeframe: record.timeframe,
        subtimeframe: record.subtimeframe,
        port_format: record.port_format,
        port_year: record.time_year,
        port_sport_league: sportLeagueMap[record.sport_league_id],
        port_sport_league_id: record.sport_league_id,
        port_sport: sportMap[record.sport_id]
      };
      console.log(pInfo);
      setPortInfo(pInfo);
      setIsLoading(false);
    })

  },[axiosPrivate,id]);


  const [portKey,setPortKey] = useState("Home");

  const handleClick = (e) => {
    //console.log("click ", e);
    console.log("handle click in Portfolio Page");
    console.log(e.key);
    setPortKey(e.key);
  };



  //const portIsCurrent = props.portInfo.port_timeframe===7;

  const getValue = useCallback(() => {

    const dataSend = {port_id:id};
    console.log(dataSend);
    axiosPrivate.post('/portfolio/getValue',dataSend)
    .then((res) => {
      console.log("getportfolio value data response");
      console.log(res.data); //debugger

      const cash = Number(res.data[0].cash);
      console.log(cash)
      setSpendCash(Number(res.data[0].cash_available).toFixed(2));
      setCash(cash.toFixed(2));
      setValue((Number(res.data[0].value)+cash).toFixed(2));
      setDisplayCash(true);
      //console.log(value)
      setIsLoading(false);

    })
  },[axiosPrivate,id])

  useEffect( () => {
    console.log("loading portfolio page")
    //console.log(props.portInfo)
    setIsLoading(true);
    getPortData();
      getValue();
      
    }, [getPortData, getValue]); 

  const updateMoney = () => {
    getValue();
  }




    return (
      <div>
        <div>Current Portfolio: {portName}</div>
        {displayCash &&
          <span>
            <p>Cash: ${cash}<br />
            Spending Cash: ${spendCash}<br />
            Total Worth: ${value}</p>
          </span>
        }
        <div></div>
        <Menu
          onClick={handleClick}
          mode="horizontal"
          defaultSelectedKeys="Home"
        >
          <Menu.Item key="Home">Portfolio Home</Menu.Item>
          {portIsCurrent && <Menu.Item key="Trade">{portInfo.port_format===0 ? "Buy/Sell" : "Add/Drop"}</Menu.Item>}
          <Menu.Item key="Performance">Performance</Menu.Item>
          {portFormat===0 && <Menu.Item key="Transactions">Transactions</Menu.Item>}
          <Menu.Item key="Settings">Settings</Menu.Item>
        </Menu>
        {!isLoading && <PortBody table={portKey} portInfo={portInfo} worth={value} tradeCallback={updateMoney} ></PortBody>}
      </div>
    );
}

export default PortfolioPage;
