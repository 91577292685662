import React from "react";

import LeagueHome from "./LeagueHome";
import MyLeaguePorts from "./MyLeaguePorts";
import LeagueSettings from "./LeagueSettings";
import {Button} from "antd";
import { Link } from "react-router-dom";
import LeaguePerformance from "./LeaguePerformance";
import LeagueInvite from "./LeagueInvite";

const LeagueBody = (props) => {

  const componentMap = {
    "": null,
    Home: <LeagueHome leagueInfo={props.leagueInfo}/>,
    Portfolio: <MyLeaguePorts league_id={props.leagueInfo.league_id} />,
    LeagueGraph: <LeaguePerformance leagueInfo={props.leagueInfo} />,
    LeagueInvite: <LeagueInvite league_id={props.leagueInfo.league_id} />,
    Settings: <LeagueSettings league_id={props.leagueInfo.league_id}/>

  };

  return (
    <div>
      {componentMap[props.table]}
      <Link to="/leagues">
            <Button>Back to My Leagues</Button>
        </Link>
    </div>
  )
}



export default LeagueBody;
