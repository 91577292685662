import useAxiosPrivate from "./useAxiosPrivate.js";
import { sportLeagueMap } from "../CONSTANTS.js";


const useGetMyLeagues = () => {

  const axiosPrivate = useAxiosPrivate();



  const getMyLeagues = async() => {
    try {
      const response = await axiosPrivate.get('/league/myLeagues');
      console.log(response);
      var data = [];
      for (let i=0; i<response.data.length; ++i){
        var obj;
        var record = response.data[i];
        
        obj = {
          //changes to this need to also be made in PortLeagues.js, CreateLeaguePage.js, JoinLeaguePage.js
          league_name: record.league_name,
          lg_id: record.league_id,
          key: record.league_id,
          lg_type: record.lg_type,
          lg_format: record.lg_format,
          timeframe: record.timeframe,
          week_start: record.subtimeframe_start,
          week_end: record.subtimeframe_end,
          sport_league: sportLeagueMap[record.sport_league_id],
          joinable: record.joinable
        }
        data.push(obj)
      }
      return data;
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }
  return getMyLeagues;
}

export default useGetMyLeagues;