import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { sportLeagueNameMap, sportLeagueTimeMap } from "../CONSTANTS.js";

import { useMediaQuery } from 'react-responsive';

const LeaguePerformance = (props) => {

  const axiosPrivate = useAxiosPrivate();

  const isLarge = useMediaQuery({minWidth:900});

  const [chartOptions,setChartOptions] = useState({})

  const [isLoading,setIsLoading] = useState(true);
  const [isErr,setIsError] = useState(false);


  const getValues = useCallback(async () => {

    setIsLoading(true);

    const dataSend = {league_id:props.leagueInfo.league_id};
    console.log(dataSend);
    console.log(props.leagueInfo);
    try {
      const response = await axiosPrivate.post('/league/getPortfoliosHist',dataSend);
      console.log(response);
      var xaxisData = ['Start'];
  
      const sport_league = props.leagueInfo.league_sport_league;
      console.log(sport_league);
  
      const timeMap = sportLeagueTimeMap[sportLeagueNameMap[sport_league]];
  
      var start = 1;
      if (props.leagueInfo.league_start>0){
        start = props.leagueInfo.league_start;
      }
      else if (props.leagueInfo.league_type===2 || props.leagueInfo.league_start===-1){
        //start = 19;
        start = timeMap[1][0];
      }
      //console.log(sportLeagueTimeMap)
      //console.log(sportLeagueNameMap[sport_league])
      //console.log(sportLeagueTimeMap[sportLeagueNameMap[sport_league]])
  
      var end = timeMap[1][1];
      if (props.leagueInfo.league_end!==-1 && props.leagueInfo.league_end!==-2){
        end = Math.min(end,props.leagueInfo.league_end);
      }
      else if (props.leagueInfo.league_end===-1){
        end = Math.min(end,timeMap[0][1]-1);
      }
      console.log(start);
      console.log(end);
  
      var portData = {};
      var maxSubtimeframe = 0;
      response.data.forEach( (record) => {
        //console.log(record);
        const pid = record?.port_id;
        if (!portData.hasOwnProperty(pid)){
            if (record.subtimeframe===null){
                return;
            }
            if (record.subtimeframe>maxSubtimeframe){
              maxSubtimeframe = record.subtimeframe;
            }
            portData[pid] = {};
            portData[pid].name = record.port_name;
            portData[pid].id = pid;
            portData[pid].yaxisData = [];
            for (var i=0; i<record.subtimeframe; i++){
                portData[pid].yaxisData.push(null);
            }
        }
        if (record.subtimeframe===null){
          return;
        }
        if (record.subtimeframe>maxSubtimeframe){
          maxSubtimeframe = record.subtimeframe;
        }
        portData[pid].yaxisData.push(Number(record.worth).toFixed(2));
      })
  
      if (maxSubtimeframe < end){
        end = maxSubtimeframe;
      }

      console.log(start);
      console.log(end);
      for (var i=start; i<=end; i++){
        xaxisData.push("Week " + i);
      }
      //console.log(yaxisData);
      //console.log(xaxisData);
  
      const dataset =     Object.values(portData).map( (portfolio) => (
        {
              data: portfolio.yaxisData,
              type: 'line',
              name: portfolio.name,
              id: portfolio.id,
              /*selectedMode: 'series',
              select: {
                  endLabel: {
                      show: true,
                      formatter: '{a}: $ {c}'
                    }
              },*/
              endLabel: {
                  show: true,
                  formatter: '{a}: $ {c}'
                },
              /*label: {
                show: true,
                formatter: '$ {c}'
              }, */
              emphasis: {
                  disabled: false,
                  focus: 'series',
                  blurScope: 'coordinateSystem',
                  endLabel: {
                      show: true,
                      formatter: '{a}: $ {c}'
                  },
                  label: {
                      show: true,
                      formatter: '$ {c}'
                  },
              }, 
        }
      ))
      const options = {
        animationDuration: 5000,
        title: {
          text: 'Season Performance'
        },
        grid : {
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          order: 'valueDesc',
          showContent: false,
          extraCssText: 'width:10; white-space:pre-wrap;',
          /*formatter: function (params) {
            var display = params[0].name + '<br />';
            params.forEach((item)=> {
              display += item.seriesName + '$' + item.value + '<br />';
            });
            return display;
          } */
        },
        xAxis: {
          type: 'category', //try value if this isnt good
          boundaryGap: false,
          data: xaxisData,
          name: "Week",
          axisPointer: {
            show:true,
            triggerEmphasis: false
          },
        },
        yAxis: {
          type: 'value',
          axisPointer: {
            show:false,
            triggerEmphasis: false
          },
          /*min: function (value) {
            return value.min*0.95;
          },
          max: function (value) {
            return (value.max*1.05).toFixed(0);
          } */
          scale: true
    
        },
        dataZoom: [
            {
              type: 'inside',
              xAxisIndex: [0],
              filterMode: 'none'
            },
            {
              type: 'inside',
              yAxisIndex: [0],
              filterMode: 'none'
            },
            {
              type: 'slider',
              xAxisIndex: [0],
              filterMode: 'none'
            },
            {
              type: 'slider',
              yAxisIndex: [0],
              filterMode: 'none'
            }
          ],
        series: dataset
      };
  
  
  
  
      setChartOptions(options);
      setIsLoading(false);
      setIsError(false);
    }

    catch (err) {

      setIsError(true);
      setIsLoading(false);

    }

  },[axiosPrivate,props.leagueInfo])

  useEffect( () => {
    console.log("loading portfolio performance page")
    //console.log(props.portInfo.port_id)
      getValues();
    }, [getValues]); //empty dependency array means function only called once on mount





  return (
    <div>
        {!isLoading && !isErr && <ReactEcharts option={chartOptions}  style={{height: isLarge ? '600px' : '450px'}}/>}
        {isErr && <p>Error occurred getting league portfolio performance history.</p>}
    </div>
  )
}



export default LeaguePerformance;
