

import React, { useState, } from "react";

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";

import { Button, Menu, Form,} from "antd";


const  AdminEmail = (props) => {



  const [view,setView] = useState("All");
  const [prevView,setPrevView] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const [isSuccess,setIsSuccess] = useState(false);



  const successfulUpdate = () => {
    setIsSuccess(true);
    setPrevView(view);
    setView("All");
  }

  const repeatAction = () => {
    setView(prevView);
    setIsSuccess(false);
  }


  const sendEmail = async (type) => {
    console.log(type);
    if (type===1){
      await axiosPrivate.post('/email/sendSiteNews');
    }
    else if (type===2){
      await axiosPrivate.post('/email/sendTradingOpen');
    }
    else if (type===3){
      await axiosPrivate.post('/email/sendTradingReminder');
    }

    successfulUpdate();
  }


  const handleClickAdmin = (e) => {
    //console.log("click ", e);
    console.log("handle click Admin");
    console.log(e);
    console.log(e.key);
    setIsSuccess(false);
    setView(e.key);

  };





  const componentMap = {
    "": null,
    siteNews: (
      <div>

        <div>
            <Form onFinish={e=>sendEmail(1)}>
                <div>Send Current (Prepared on Server) Site News Email</div>
                <Form.Item>
                <Button type='primary' htmlType="submit">Send Email</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    ),
    tradingOpen: (
      <div>

        <div>
            <Form onFinish={e=>sendEmail(2)}>
                <div>Send Email that Trading is Open</div>
                <Form.Item>
                <Button type='primary' htmlType="submit">Send Email</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    ),
    weekendReminder: (
      <div>

        <div>
            <Form onFinish={e=>sendEmail(3)}>
                <div>Send Email Reminder</div>
                <Form.Item>
                <Button type='primary' htmlType="submit">Send Email</Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    )
  };

  return (
      <div>
        {isSuccess && <div>{prevView} Update Successful</div>}
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="siteNews" ><Button>Send Site News</Button></Menu.Item>
            <Menu.Item key="tradingOpen"><Button>Trading Open</Button></Menu.Item>
            <Menu.Item key="weekendReminder"><Button>Weekend Reminder</Button></Menu.Item>
        </Menu>



        {isSuccess && <Button onClick={repeatAction}>Repeat Action</Button>}
        {view!=="All" && <div>{view}</div>}
        {componentMap[view]}
      </div>
  );
  
}

export default AdminEmail;
