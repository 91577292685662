import React from "react";
import Admin from "./Admin/Admin.js";
import League from "./League/League.js";
//import RestrictedNFLTable from "./RestrictedNFLTable.js";
import Market from "./Market.js";
import AdminStock from "./Admin/AdminStock.js";
import AdminGame from "./Admin/AdminGame.js";
//import RestrictedTable from "./RestrictedTable.js";
import About from "./About.js";
import SiteNews from './SiteNews.js';
import CreatePortfolio from "./Portfolio/CreatePortfolio.js";
import AddPortToLeague from "./Portfolio/AddPortToLeague.js";
import PortfolioPage from "./Portfolio/PortfolioPage.js";
import MyPortfoliosPage from "./Portfolio/MyPortfoliosPage.js";
import CreateLeaguePage from "./League/CreateLeaguePage.js";
import JoinLeaguePage from "./League/JoinLeaguePage.js";
import MyLeaguePage from "./League/MyLeaguesPage.js";
import RestrictedTable from "./RestrictedTable.js";

import './App.css';
import AdminPost from "./Admin/AdminPost.js";
import AdminEmail from "./Admin/AdminEmail.js";

import { Routes, Route } from "react-router-dom";
import MarketNotFound from "./RouterHelpers/MarketNotFound.js";
import PageNotFound from "./RouterHelpers/PageNotFound.js";

const Body = (props) => {






  /*const componentMap = {
    "": null,
    Home: <BodyHome />,
    CreatePortfolio: <Portfolio type={"CreatePortfolio"} portCallback={updatePortandGo}/>,
    AddPortToLeague: <Portfolio type={"AddPortToLeague"} portCallback={updatePortandGo}/>,
    MyPortfolios: <Portfolio type={"MyPortfolios"} portCallback={updatePortandGo}/>,
    Portfolio: <Portfolio type={"Portfolio"} portCallback={updatePortandGo}/>,
    Portfolios: <Portfolio type={"Portfolios"} portInfo={portInfo} leagueCallback={updateLeagueandGo}/>,
    League: <League type={"League"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    CreateLeague: <League type={"CreateLeague"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    JoinLeague: <League type={"JoinLeague"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    MyLeagues: <League type={"MyLeagues"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    Leagues: <League type={"Leagues"} leagueInfo={leagueInfo} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    Market: <Market year={2024} hist={false} type="Reg" sport="NFL" transact={false}/>,
    GlobalMarket: <div> Market<Market year={2024} transact={false}/> </div>,
    NCAAMMarket: <Market year={2024} hist={false} type="Playoffs" sport="NCAAM" transact={false}/>,
    NFLMarket: <Market year={2024} hist={false} type="Reg" sport="NFL" transact={false}/>,
    NFLPlayoffMarket: <Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={false}/>,
    NBAmarket: <Market year={2024} hist={false} sport="NBA" transact={false}/>,
    Restricted: <RestrictedTable type="Reg" sport="NFL" />,
    Restricted: <RestrictedTable type="Playoffs" sport="NFL" />,
    RestrictNFLMarket: <RestrictedNFLTable type="Reg"/>,
    RestrictNFLPlayoffMarket: <RestrictedNFLTable type="Playoffs"/>,

    NFLMarket2023: <Market year={2023} hist={true} type="Reg" sport="NFL" transact={false}/>,
    NFLPlayoffMarket2023: <Market year={2023} hist={true} type="Playoffs" sport="NFL" transact={false}/>,

    News: <News />,
    Admin: <Admin />,
    AdminUniversal: <Admin />,
    AdminStock: <AdminStock />,
    AdminGame: <AdminGame />,
    AdminPost: <AdminPost />,
    AdminEmail: <AdminEmail />
  };

  */





  return (
    <div>
      <div class="column side">
          <SiteNews />
      </div>
      <div class="column middle">
      <Routes>
            <Route path= "/" element={<About />} />
            <Route path= "/home" element={<About/>} />
            <Route path= "/about" element={<About />} />
            <Route path= "/market" element={<Market year={2024} hist={false} type="Reg" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFL" element={<Market year={2024} hist={false} type="Reg" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFL/2024" element={<Market year={2024} hist={false} type="Reg" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFL/2023" element={<Market year={2023} hist={true} type="Reg" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFL/*" element={<MarketNotFound />} />
            <Route path= "/market/NFLPlayoffs" element={<Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFLPlayoffs/2024" element={<Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFLPlayoffs/2023" element={<Market year={2023} hist={true} type="Playoffs" sport="NFL" transact={0}/>} />
            <Route path= "/market/NFLPlayoffs/*" element={<MarketNotFound />} />
            <Route path= "/market/NCAAM" element={<Market year={2024} hist={false} type="Playoffs" sport="NCAAM" transact={0}/>} />
            <Route path= "/market/NCAAM/2024" element={<Market year={2024} hist={false} type="Playoffs" sport="NCAAM" transact={0}/>} />
            <Route path= "/market/NCAAM/*" element={<MarketNotFound />} />
            <Route path= "/market/restricted" element={<RestrictedTable type="Playoffs" sport="NFL" />} />
            <Route path= "/market/*" element={<MarketNotFound />} />
            <Route path= "/portfolio/create" element={<CreatePortfolio />} />
            <Route path= "/portfolio/add" element={<AddPortToLeague />} />
            <Route path= "/portfolio/:id" element={<PortfolioPage />} />
            <Route path= "/portfolios" element={ <MyPortfoliosPage />} />
            <Route path= "/league/create" element={<CreateLeaguePage />} />
            <Route path= "/league/join" element={<JoinLeaguePage />} />
            <Route path= "/league/join/:id" element={<JoinLeaguePage />} />
            <Route path= "/league/:id" element={<League />} />
            <Route path= "/leagues" element={ <MyLeaguePage />} />
            <Route path= "/admin" element={ <Admin />} />
            <Route path= "/admin/universal" element={ <Admin />} />
            <Route path= "/admin/stock" element={ <AdminStock />} />
            <Route path= "/admin/post" element={ <AdminPost />} />
            <Route path= "/admin/email" element={ <AdminEmail />} />
            <Route path= "/admin/game" element={ <AdminGame />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
      </div>
      <div class="column side"></div>
    </div>


  );
}

export default Body;
