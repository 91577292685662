import {Form, Input, Select, Button, Checkbox, Radio} from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useGetMyLeagues from "../hooks/useGetMyLeagues.js";


const CreatePortfolio = (props) => {


  const axiosPrivate = useAxiosPrivate();
 
  const [isCompleted,setIsCompleted] = useState(false);

  const getMyLeagues = useGetMyLeagues();
  const [leagueNames,setLeagueNames] = useState([]);

  const [isErr,setIsErr] = useState(false);
  const [errMsg,setErrMsg] = useState("");
  const [isSubmitting,setIsSubmitting] = useState(false);

  const [sitewideJoinStatus,setsitewideJoinStatus] = useState(false);
  const [sprtLgToJoin,setSprtLgToJoin] = useState(1);

  const getMyLeagueInfo = useCallback(async() => {
    try {
      setIsSubmitting(true);
      const myLeagues = await getMyLeagues();
      console.log(myLeagues);
      console.log(myLeagues.length);
      const leagueOptions = myLeagues.filter(league => league.joinable).map( (league) => (
        {
          value: league.lg_id,
          label: league.league_name
        }
      ))
      console.log(leagueOptions);
      setLeagueNames(leagueOptions);
      setsitewideJoinStatus((leagueOptions.length===0));
      setIsSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setIsErr(true);
      setIsSubmitting(false);
      return err;
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps



  useEffect( () => {
    getMyLeagueInfo();
  }, [getMyLeagueInfo]); //empty dependency array means function only called once on mount


  const createPortClick = async (e) => {
    console.log("here in create port submit");
    console.log(e);

    var data = e;


    data.sitewide = sitewideJoinStatus;
    if (sitewideJoinStatus){
      if (sprtLgToJoin===1){
        data.sport_league_id = 1;
        data.season_type = 2; //regular season
      }
      else if (sprtLgToJoin===7){
        data.sport_league_id = 1;
        data.season_type = 3; //playoffs
      }

    }

    console.log(data);

    try {
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/portfolio/create',data);
      console.log(response);
      setIsCompleted(true);
      setIsSubmitting(false);
      setIsErr(false);

      console.log("portfolio create successful");

    }
    catch(err){
      console.log(err);

      if (err.response?.data?.message){
        console.log(err.response?.data?.message);
        setErrMsg("Portfolio Create Failed: " + err.response?.data?.message);
      }
      else {
        setErrMsg("Portfolio Create Failed: " + err.message);
      }
      setIsErr(true);
      setIsSubmitting(false);
    }
  }

  const sitewideJoinStatusOnChange = (e) => {
    //console.log(e);
    setsitewideJoinStatus(!sitewideJoinStatus);
  }

  const sprtLgOnChange = (e) => {
    //console.log(e);
    setSprtLgToJoin(e.target.value);
  }



  const tryAgain = () => {
    //setCreatedID(-1);
    setErrMsg("");
    setIsErr(false);
  }

  const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }

  //console.log("creating port page");
  return (

    <div>
      {(!isSubmitting &&  !isCompleted && !isErr) && <span>
        <Form {...layout}  onFinish={createPortClick}>
          <Form.Item label="Portfolio Name" name="portname" rules={[{ required: true, message: "Portfolio name required." }]}>
            <Input />
          </Form.Item>


            <Checkbox onChange={(sitewideJoinStatusOnChange)} checked={sitewideJoinStatus} disabled={leagueNames.length===0}>Join Sitewide League?</Checkbox>
          {sitewideJoinStatus 
          ? <span><div>Joining Sitewide League for:</div>
            <Radio.Group value={sprtLgToJoin} onChange={(sprtLgOnChange)}>
              <Radio value={1}>NFL</Radio>
              {<Radio value={7}>NFL Playoffs</Radio>}
              {/*<Radio value={6}>NCAAM</Radio>*/}
              </Radio.Group></span>
          :
          <Form.Item label="League" name="league_id" rules={[{ required: true, message: "Must select a league." }]}>
          <Select placeholder="Please choose a league" options={leagueNames}>
          </Select>
        </Form.Item>
          }


          <Form.Item>
            <Button type="primary" htmlType="submit" >Create Portfolio</Button>
          </Form.Item>
        </Form>
        <Form>
          <Form.Item>
            <Link to="/portfolio/add">
              <Button  >Or Add Existing Portfolio To a League</Button>
            </Link>
          </Form.Item>
        </Form>
      </span>
    }
    {leagueNames.length<=0 && <div>You must create or join a league in order to a Portfolio.</div>}
    {((isCompleted) && 
    <div><p>Portfolio Successfully Created</p>
      <Form>
        <Form.Item>
          <Link to="/portfolio/add">
            <Button>Add Portfolio to Another League</Button>
          </Link>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Link to="/portfolios">
            <Button  >Go To My Portfolios</Button>
          </Link>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Button  onClick={(e) => {setIsCompleted(false)}} >Create Another Portfolio</Button>
        </Form.Item>
      </Form>
      </div>
    )}
      {isErr && <div>{errMsg}<p>Failed to create portfolio.</p>
      <Form>
        <Form.Item>
          <Button  onClick={tryAgain} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
    </div>
  )
}

export default CreatePortfolio;