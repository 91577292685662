export const VERSION = "0.3.2.2"

export const SERVER_VERSION_REQUIRED = "0.1.7.1"

export const SITE_URL = "www.spoxmarket.com"

export const ARCHIVE_THRESHOLD = 5;

export const PORTFOLIO_FORMAT = 0;
export const ROSTER_FORMAT = 1;

export const sportLeagueMap = {
  0: "All",
  1: "NFL",
  2: "NBA",
  3: "MLB",
  4: "NHL",
  5: "NCAAF",
  6: "NCAAM"
}

export const sportLeagueNameMap = {
  "NFL": 1,
  "NCAAM": 6
}

export const timeframeStatusMap = {
  0: "not started",
  1: "in progress",
  2: "finished"
}

// 0 is career, 1 is calendar year, 2 is regular season, 3 is playoffs, 4 is all star game, 5 is in-season tournament
export const timeframeTypeMap = {
  0: "career",
  1: "year",
  2: "regular season",
  3: "playoffs",
  4: "all star game",
  5: "in-season tournament"
}

export const timeframeToString = {
  1: "Career",
  2: "2023",
  3: "2023 NFL",
  4: "2023 NFL Playoffs",
  5: "2024",
  6: "2024 NCAAM",
  7: "2024 NFL",
  8: "2024 NFL Playoffs",
  9: "2025",
  10: "2025 NCAAM"
}

export const sportLeagueTimeMap = {
  1: [[1,19],[19,23]],
  6: [[],[1,8]]
}

export const sportMap = {
  1: "Football",
  2: "Basketball",
  3: "Baseball",
  4: "Hockey",
  5: "Soccer"
}

export const positionMap = {
  1: "TEAM",
  2: "QB",
  3: "RB",
  4: "WR",
  5: "TE",
  6: "K"
};

export const leagueFormatMap = {
  0: "Portfolio",
  1: "Roster"
}

export const LeagueTypeToTimeframeType = {
  0: 2,
  1: 2,
  2: 3,
  3: 2,
}

export const TimeframeTypeCurrentTimeframe = {
  0: { //sport_league_id===0
    0: 1, //career
    1: 5, //year 
  },
  1: {  //NFL
    2: 7,  //Regular Season
    3: 8  //Playoffs
  },
  6: { //NCAAM
    3: 10
  }
}

/*
// Function to get the value from the dictionary
function getCurrTimeframe(sport_league, tf_type) {
  if (dictionary[sport_league] && dictionary[sport_league][tf_type] !== undefined) {
      return dictionary[sport_league][tf_type];
  } else {
      throw new Error('Invalid type or subtype');
  }
} */

export const positionOptions = [
  {
    value:1,
    label: "TEAM"
  },
  {
    value:2,
    label: "QB"
  },
  {
    value:3,
    label: "RB"
  },
  {
    value:4,
    label: "WR"
  },
  {
    value:5,
    label: "TE"
  },
  {
    value:6,
    label: "K"
  }
];

export const positionItems = [
  {
    key:1,
    label: "TEAM"
  },
  {
    key:2,
    label: "QB"
  },
  {
    key:3,
    label: "RB"
  },
  {
    key:4,
    label: "WR"
  },
  {
    key:5,
    label: "TE"
  },
  {
    key:6,
    label: "K"
  }
];


export const NFLteamFilters = [
    {
      text: "Arizona Cardinals",
      value: "Arizona Cardinals"
    },
    {
      text: "Atlanta Falcons",
      value: "Atlanta Falcons"
    },
    {
      text: "Baltimore Ravens",
      value: "Baltimore Ravens"
    },
    {
      text: "Buffalo Bills",
      value: "Buffalo Bills"
    },
    {
      text: "Carolina Panthers",
      value: "Carolina Panthers"
    },
    {
      text: "Chicago Bears",
      value: "Chicago Bears"
    },
    {
      text: "Cincinnati Bengals",
      value: "Cincinnati Bengals"
    },
    {
      text: "Cleveland Browns",
      value: "Cleveland Browns"
    },
    {
      text: "Dallas Cowboys",
      value: "Dallas Cowboys"
    },
    {
      text: "Denver Broncos",
      value: "Denver Broncos"
    },
    {
      text: "Detroit Lions",
      value: "Detroit Lions"
    },
    {
      text: "Green Bay Packers",
      value: "Green Bay Packers"
    },
    {
      text: "Houston Texans",
      value: "Houston Texans"
    },
    {
      text: "Indianapolis Colts",
      value: "Indianapolis Colts"
    },
    {
      text: "Jacksonville Jaguars",
      value: "Jacksonville Jaguars"
    },
    {
      text: "Kansas City Chiefs",
      value: "Kansas City Chiefs"
    },
    {
      text: "Las Vegas Raiders",
      value: "Las Vegas Raiders"
    },
    {
      text: "Los Angeles Chargers",
      value: "Los Angeles Chargers"
    },
    {
      text: "Los Angeles Rams",
      value: "Los Angeles Rams"
    },
    {
      text: "Miami Dolphins",
      value: "Miami Dolphins"
    },
    {
      text: "Minnesota Vikings",
      value: "Minnesota Vikings"
    },
    {
      text: "New England Patriots",
      value: "New England Patriots"
    },
    {
      text: "New Orleans Saints",
      value: "New Orleans Saints"
    },
    {
      text: "New York Giants",
      value: "New York Giants"
    },
    {
      text: "New York Jets",
      value: "New York Jets"
    },
    {
      text: "Philadelphia Eagles",
      value: "Philadelphia Eagles"
    },
    {
      text: "Pittsburgh Steelers",
      value: "Pittsburgh Steelers"
    },
    {
      text: "San Francisco 49ers",
      value: "San Francisco 49ers"
    },
    {
      text: "Seattle Seahawks",
      value: "Seattle Seahawks"
    },
    {
      text: "Tampa Bay Buccaneers",
      value: "Tampa Bay Buccaneers"
    },
    {
      text: "Tennessee Titans",
      value: "Tennessee Titans"
    },
    {
      text: "Washington Commanders",
      value: "Washington Commanders"
    }
  ];

export const NFLposFilters = [
    {
      text: "TEAM",
      value: "TEAM"
    },
    {
      text: "QB",
      value: "QB"
    },
    {
      text: "RB",
      value: "RB"
    },
    {
      text: "WR",
      value: "WR"
    },
    {
      text: "TE",
      value: "TE"
    },
    {
      text: "K",
      value: "K"
    }
  ];


export  const NFLteamMap = new Map([
    [1,'Pittsburgh Steelers'],
    [2,'Baltimore Ravens'],
    [3,'Cleveland Browns'],
    [4,'Cincinnati Bengals'],
    [5,'Houston Texans'],
    [6,'Indianapolis Colts'],
    [7,'Tennessee Titans'],
    [8,'Jacksonville Jaguars'],
    [9,'Los Angeles Chargers'],
    [10,'Las Vegas Raiders'],
    [11,'Kansas City Chiefs'],
    [12,'Denver Broncos'],
    [13,'New England Patriots'],
    [14,'Miami Dolphins'],
    [15,'New York Jets'],
    [16,'Buffalo Bills'],
    [17,'Minnesota Vikings'],
    [18,'Chicago Bears'],
    [19,'Green Bay Packers'],
    [20,'Detroit Lions'],
    [21,'Carolina Panthers'],
    [22,'New Orleans Saints'],
    [23,'Tampa Bay Buccaneers'],
    [24,'Atlanta Falcons'],
    [25,'Los Angeles Rams'],
    [26,'Seattle Seahawks'],
    [27,'Arizona Cardinals'],
    [28,'San Francisco 49ers'],
    [29,'Washington Commanders'],
    [30,'Philadelphia Eagles'],
    [31,'Dallas Cowboys'],
    [32,'New York Giants']
  ]);

export  const NFLteamAbbrevMap = new Map([
    [1,'PIT'],
    [2,'BAL'],
    [3,'CLE'],
    [4,'CIN'],
    [5,'HOU'],
    [6,'IND'],
    [7,'TEN'],
    [8,'JAC'],
    [9,'LAC'],
    [10,'LV'],
    [11,'KC'],
    [12,'DEN'],
    [13,'NE'],
    [14,'MIA'],
    [15,'NYJ'],
    [16,'BUF'],
    [17,'MIN'],
    [18,'CHI'],
    [19,'GB'],
    [20,'DET'],
    [21,'CAR'],
    [22,'NO'],
    [23,'TB'],
    [24,'ATL'],
    [25,'LAR'],
    [26,'SEA'],
    [27,'ARI'],
    [28,'SF'],
    [29,'WAS'],
    [30,'PHI'],
    [31,'DAL'],
    [32,'NYG']
  ]);

export  const NFLteamItems = [
    {key:1,label:'Pittsburgh Steelers'},
    {key:2,label:'Baltimore Ravens'},
    {key:3,label:'Cleveland Browns'},
    {key:4,label:'Cincinnati Bengals'},
    {key:5,label:'Houston Texans'},
    {key:6,label:'Indianapolis Colts'},
    {key:7,label:'Tennessee Titans'},
    {key:8,label:'Jacksonville Jaguars'},
    {key:9,label:'Los Angeles Chargers'},
    {key:10,label:'Las Vegas Raiders'},
    {key:11,label:'Kansas City Chiefs'},
    {key:12,label:'Denver Broncos'},
    {key:13,label:'New England Patriots'},
    {key:14,label:'Miami Dolphins'},
    {key:15,label:'New York Jets'},
    {key:16,label:'Buffalo Bills'},
    {key:17,label:'Minnesota Vikings'},
    {key:18,label:'Chicago Bears'},
    {key:19,label:'Green Bay Packers'},
    {key:20,label:'Detroit Lions'},
    {key:21,label:'Carolina Panthers'},
    {key:22,label:'New Orleans Saints'},
    {key:23,label:'Tampa Bay Buccaneers'},
    {key:24,label:'Atlanta Falcons'},
    {key:25,label:'Los Angeles Rams'},
    {key:26,label:'Seattle Seahawks'},
    {key:27,label:'Arizona Cardinals'},
    {key:28,label:'San Francisco 49ers'},
    {key:29,label:'Washington Commanders'},
    {key:30,label:'Philadelphia Eagles'},
    {key:31,label:'Dallas Cowboys'},
    {key:32,label:'New York Giants'}
  ];

export  const NFLPlayoffTeamItems = [
    {key:1,label:'Pittsburgh Steelers'},
    {key:2,label:'Baltimore Ravens'},
    {key:5,label:'Houston Texans'},
    {key:9,label:'Los Angeles Chargers'},
    {key:11,label:'Kansas City Chiefs'},
    {key:12,label:'Denver Broncos'},
    {key:16,label:'Buffalo Bills'},
    {key:17,label:'Minnesota Vikings'},
    {key:19,label:'Green Bay Packers'},
    {key:20,label:'Detroit Lions'},
    {key:23,label:'Tampa Bay Buccaneers'},
    {key:25,label:'Los Angeles Rams'},
    {key:29,label:'Washington Commanders'},
    {key:30,label:'Philadelphia Eagles'}
  ];


export  const NFLteamOptions = [
  {value:27,label:'Arizona Cardinals'},
  {value:24,label:'Atlanta Falcons'},
  {value:2,label:'Baltimore Ravens'},
  {value:16,label:'Buffalo Bills'},
  {value:21,label:'Carolina Panthers'},
  {value:18,label:'Chicago Bears'},
  {value:4,label:'Cincinnati Bengals'},
  {value:3,label:'Cleveland Browns'},
  {value:31,label:'Dallas Cowboys'},
  {value:12,label:'Denver Broncos'},
  {value:20,label:'Detroit Lions'},
  {value:19,label:'Green Bay Packers'},
  {value:5,label:'Houston Texans'},
  {value:6,label:'Indianapolis Colts'},
  {value:8,label:'Jacksonville Jaguars'},
  {value:11,label:'Kansas City Chiefs'},
  {value:10,label:'Las Vegas Raiders'},
  {value:9,label:'Los Angeles Chargers'},
  {value:25,label:'Los Angeles Rams'},
  {value:14,label:'Miami Dolphins'},
  {value:17,label:'Minnesota Vikings'},
  {value:13,label:'New England Patriots'},
  {value:22,label:'New Orleans Saints'},
  {value:32,label:'New York Giants'},
  {value:15,label:'New York Jets'},
  {value:30,label:'Philadelphia Eagles'},
  {value:1,label:'Pittsburgh Steelers'},
  {value:28,label:'San Francisco 49ers'},
  {value:26,label:'Seattle Seahawks'},
  {value:23,label:'Tampa Bay Buccaneers'},
  {value:7,label:'Tennessee Titans'},
  {value:29,label:'Washington Commanders'}
    ];

export  const NFLPlayoffteamOptions = [
      {value:2,label:'Baltimore Ravens'},
      {value:16,label:'Buffalo Bills'},
      {value:12,label:'Denver Broncos'},
      {value:20,label:'Detroit Lions'},
      {value:19,label:'Green Bay Packers'},
      {value:5,label:'Houston Texans'},
      {value:11,label:'Kansas City Chiefs'},
      {value:9,label:'Los Angeles Chargers'},
      {value:25,label:'Los Angeles Rams'},
      {value:17,label:'Minnesota Vikings'},
      {value:30,label:'Philadelphia Eagles'},
      {value:1,label:'Pittsburgh Steelers'},
      {value:23,label:'Tampa Bay Buccaneers'},
      {value:29,label:'Washington Commanders'}
        ];


export const NFLplayoffTeamFilters = [
    {
      text:  "Baltimore Ravens",
      value: "Baltimore Ravens"
    },
    {
      text:  "Denver Broncos",
      value: "Denver Broncos"
    },
    {
      text:  "Buffalo Bills",
      value: "Buffalo Bills"
    },
    {
      text:  "Detroit Lions",
      value: "Detroit Lions"
    },
    {
      text:  "Green Bay Packers",
      value: "Green Bay Packers"
    },
    {
      text:  "Houston Texans",
      value: "Houston Texans"
    },
    {
      text:  "Kansas City Chiefs",
      value: "Kansas City Chiefs"
    },
    {
      text:  "Los Angeles Chargers",
      value: "Los Angeles Chargers"
    },
    {
      text:  "Los Angeles Rams",
      value: "Los Angeles Rams"
    },
    {
      text:  "Minnesota Vikings",
      value: "Minnesota Vikings"
    },
    {
      text:  "Philadelphia Eagles",
      value: "Philadelphia Eagles"
    },
    {
      text:  "Pittsburgh Steelers",
      value: "Pittsburgh Steelers"
    },
    {
      text:  "Tampa Bay Buccaneers",
      value: "Tampa Bay Buccaneers"
    },
    {
      text:  "Washington Commanders",
      value: "Washington Commanders"
    }
  ];

