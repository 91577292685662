import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Tag, Tooltip} from "antd";
import "antd/dist/antd.min.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useContext } from "react";
import AuthContext from "../contexts/Authentication.js";
import '../Global.css';
import PortTable from "../Portfolio/PortTable.js";
import PortRoster from "../Portfolio/PortRoster.js";

import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";

const LeagueTable = (props) => {

  const {auth} = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [tableData,setTableData] = useState([{}]);

  const [isPortSelected,setIsPortSelected] = useState(false);
  const [isPortMine,setIsPortMine] = useState(false);

  const [portToView,setPortToView] = useState(-1);
  const [portName,setPortName] = useState("");
  const [portUser,setPortUser] = useState("");
  
  const isXSmall = useMediaQuery({maxWidth:575});

  const axiosPrivate = useAxiosPrivate();

  const fetchData = useCallback( () => {
    console.log(props.leagueInfo)
    setIsLoading(true);
    var data = [];
    const dataSend = {
      league_id:props.leagueInfo.league_id,
      timeframe: props.leagueInfo.league_timeframe
    };
    console.log(dataSend);
    axiosPrivate.post('/league/getPortfolios',dataSend)
    .then((res) => {
      console.log("getportfolio data response");
      //console.log(res.data); //debugger
      for (let i=0; i<res.data.length; ++i){
        var obj;
        var record = res.data[i];
        console.log(record);
        const currworth = (Number(record.cash)+Number(record.val)).toFixed(2);
        var change;
        if (record?.prevworth){
          change = (100*(currworth-Number(record.prevworth))/Number(record.prevworth)).toFixed(2);
        }
        else {
          change = null;
        }
        obj = {
          user: record.username,
          pname:record.port_name,
          port_id: record.port_id,
          worth: currworth,
          change: change,
          key: record.port_id,
          viewable: record.viewable,
          mine: record.username===auth.user
          //viewable: false

        }
        data.push(obj)
      }
      setTableData(data);
      setIsLoading(false);
    })
  }, [axiosPrivate,props.leagueInfo,auth.user])

  useEffect( () => {
    fetchData();
  }, [fetchData]); //empty dependency array means function only called once on mount

 const viewPort = (record) => {
  setPortToView(record.port_id);
  setPortName(record.pname);
  setPortUser(record.user);
  setIsPortMine(record.mine);
  setIsPortSelected(true);
 }

 const backToLeague = () => {
  setIsPortSelected(false);
  setPortToView(-1);
 }

  var columns = [
    {
      title: isXSmall ? "#" : 'Rank',
      dataIndex: 'key',
      rowScope: 'row',
      width: isXSmall ? '34px': 'auto',
      render: (text,record,index) =>(
        <span>{index+1}</span>
      )
    },
    {
      title: isXSmall ? "Port Name" : "Portfolio Name",
      dataIndex: "pname",
      key: "pname",
      render: (text,record) =>(
        record.mine
        ?
          <Link to={"/portfolio/" + record.key}>
            <Button style={{whiteSpace: "normal",height:'auto', textAlign:'left'}} size='small' type='link' >{text}</Button>
          </Link>
        :
          record.viewable
          ?
            <Button style={{whiteSpace: "normal",height:'auto', textAlign:'left'}} size='small' type='link' onClick={(e)=>viewPort(record)}>{text}</Button>
          :
            <span>{text}</span>
      )
    },

    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ellipsis: {
        showTitle: isXSmall,
      },
      render: (user) => (
        isXSmall ? <Tooltip placement="topLeft" title={user}>
          {user}
        </Tooltip> : user
      ),
    },
    {
      title: "Total Worth",
      dataIndex: "worth",
      key: "worth",
      render : (text) => (
        "$"+ Number(text).toFixed(0)
      ),
      defaultSortOrder: 'descend',
      sortDirections: ['descend','ascend','descend'],
      sorter: {
        compare: (a, b) => a.worth - b.worth
      }
    },
    {
      title: "Weekly Change",
      dataIndex: "change",
      key: "change",
      sortDirections: ['descend','ascend'],
      sorter:  (a, b) => a.change - b.change,
      render : (text,record) => (
        record.change ? (record.change>0 ? <Tag color='green'>{text}%</Tag> : (record.change<0 ? <Tag color='red'>{text}%</Tag> : <Tag>{text}%</Tag>)) : <span>-</span>

      )
    },
  ];



    return (
      <span>
        {isPortSelected &&
        <span>
          <div>
          <Button onClick={backToLeague}>Back to League</Button>
          </div>
          {props.leagueInfo.league_format===0
                ? <span><PortTable sell={false} leagueInfo={props.leagueInfo} port_id={portToView} mine={isPortMine} portName={portName} user={portUser} from={"League"}></PortTable></span>
                : <span><p>Roster:</p><PortRoster leagueInfo={props.leagueInfo} port_id={portToView} mine={isPortMine} from={"League"}/></span>}

        </span>}
      {!isPortSelected && <div>
        <p>Portfolios in this League:</p>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={isLoading}
          rowClassName={(record) => (record.user===auth.user ? "mine" : "")}
          size= {!isXSmall ? "large" : "small"}
        />
      </div>}
      
      </span>
    );

  }

  export default LeagueTable