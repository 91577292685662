import React, { useState, useCallback, useEffect } from "react";

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { useParams } from "react-router-dom";

import LeaguePage from "./LeaguePage.js";
import { sportLeagueMap } from "../CONSTANTS.js";


const League = (props) => {

  const params = useParams();
  const id = params.id;

  const axiosPrivate = useAxiosPrivate();

  
  const [leagueInfo,setLeagueInfo] = useState({});
  const [isLoading,setIsLoading] = useState(true);

  const getLeagueInfo = useCallback(() => {

    //console.log(params)

    const dataSend = {league_id:id};
    console.log(dataSend);
    //TODO need a message for not having access to league
    axiosPrivate.post('/league/myLeague',dataSend).then ((res) => {
      console.log(res.data);

      const record = res.data[0];
      console.log(record)

      const lInfo = {
        league_name:record.league_name,
        league_id:record.league_id,
        league_type: record.lg_type,
        league_format: record.lg_format,
        league_timeframe: record.timeframe,
        league_start: record.subtimeframe_start,
        league_end: record.subtimeframe_end,
        league_sport_league: sportLeagueMap[record.sport_league_id],
        league_sport_league_id: record.sport_league_id
      };
      console.log(lInfo);
      setLeagueInfo(lInfo);
      setIsLoading(false);
    })

  },[axiosPrivate,id]);


  useEffect( () => {

    getLeagueInfo();
      
    }, [getLeagueInfo]); 


  return (
    <div>
      {!isLoading && <LeaguePage leagueInfo={leagueInfo} />}
    </div>
  )
}


export default League;
