import React from "react";

import PortfolioTrade from "./PortfolioTrade";
import PortfolioHome from "./PortfolioHome";
import PortfolioSettings from "./PortfolioSettings";
import {Button} from "antd";
import { Link } from "react-router-dom";
import PortfolioPerformance from "./PortfolioPerformance";
import TransactionHistory from "../TransactionHistory";

const PortBody = (props) => {

  const componentMap = {
    "": null,
    Home: <PortfolioHome portInfo={props.portInfo}/>,
    Trade: <PortfolioTrade portInfo={props.portInfo} tradeCallback={props.tradeCallback}/>,
    Performance: <PortfolioPerformance port_id={props.portInfo.port_id} worth={props.worth}></PortfolioPerformance>,
    Transactions: <TransactionHistory port_id={props.portInfo.port_id} />,
    Settings: <PortfolioSettings port_id={props.portInfo.port_id}/>
  };

  return (
    <div>
      {componentMap[props.table]}
      <Link to="/portfolios">
        <Button>Back to My Portfolios</Button>
      </Link>
    </div>
  )
}



export default PortBody;
