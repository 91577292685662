import { leagueFormatMap, sportLeagueMap } from "../CONSTANTS.js";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import React, { useState, useEffect, useCallback } from "react";

const LeagueSettings = (props) => {

    const axiosPrivate = useAxiosPrivate();

    const [isLoading, setIsLoading] = useState(false);
    const [leagueName,setLeagueName] = useState("");
    const [leagueFormat,setLeagueFormat] = useState(-1);
    const [leagueCreator,setLeagueCreator] = useState("");
    const [leagueTimeframe, setLeagueTimeframe] = useState("2023");
    const [leaguePrivate,setLeaguePrivate] = useState("No")
    const [leagueBudget,setLeagueBudget] = useState(0);
    const [leagueSport,setLeagueSport] = useState("");
    const [leaguePass,setLeaguePass] = useState("");
    const [leagueShorting,setLeagueShorting] = useState("");
    //const [leagueTransType,setLeagueTransType] = useState(0);
    const [leagueTransTypeString,setLeagueTransTypeString] = useState("");
    //const [leagueTransAmnt,setLeagueTransAmnt] = useState(0.0);
    //const [leagueTransPercent,setLeagueTransPercent] = useState(0.0);
    //const [leagueTransPershare,setLeagueTransPershare] = useState(0.0);
    const [leagueInseason,setLeagueInseason] = useState("Yes");


    //roster only settings
    const [maxSharesPerTeam,setMaxSharesPerTeam] = useState("No");
    const [playoffMultipliers,setPlayoffMultipliers] = useState("No");
    const [rosterData,setRosterData] = useState(null);

    const fetchData = useCallback( async() => {
        setIsLoading(true);
        const dataSend = {league_id:props.league_id};
        console.log(dataSend);
        try {
            const response = await axiosPrivate.post('/league/getSettings',dataSend)

            const settings = response.data[0];

            console.log("get league settings data response");
            console.log(response.data); //debugger
            if (response?.data?.length>1){
                //got two sets of results for one league settings request, this shouldnt happen!
                console.log("Error in gettting league settings");
            }
            //setIsLoading(false);
            setLeagueName(settings.league_name);

            setLeagueCreator(settings.username);
            if (settings.timeframe<0){
                setLeagueTimeframe(String(settings.timeframe*-1));
            }
            else {
                setLeagueTimeframe(settings.timeframe_name);
            }
            if (settings.private){
                setLeaguePrivate("Private");
                setLeaguePass(settings.lg_pass);
            }
            else {
                setLeaguePrivate("Public")
            }
            setLeagueSport(sportLeagueMap[settings.sport_league_id]);

            if(settings.inseason){
                setLeagueInseason("Yes");
            }
            else {
                setLeagueInseason("No");
            }


            const lg_format = settings.lg_format;
            setLeagueFormat(lg_format);
            if (lg_format===0){
                setLeagueBudget(settings.budget)
                if (settings.shorting){
                    setLeagueShorting("Allowed");
                }
                else {
                    setLeagueShorting("Blocked")
                }
                //setLeagueTransType(settings.trans_cost_type);
                //setLeagueTransAmnt(settings.transaction_cost);
                //setLeagueTransPercent(settings.transaction_cost_percent);
                //setLeagueTransPershare(settings.transaction_cost_pershare);
                var transCostString = '';
                let first = false;
                if (settings.trans_cost_type===1){
                    transCostString += "Global: "
                }
                if (Number(settings.transaction_cost)>0.0){
                    if (first){
                        transCostString += " + "
                    }
                    else {
                        first = true;
                    }
                    transCostString += ("$" + Number(settings.transaction_cost).toFixed(2))
                }
                if (Number(settings.transaction_cost_percent)>0.0){
                    if (first){
                        transCostString += " + "
                    }
                    else {
                        first = true;
                    }
                    transCostString += (Number(settings.transaction_cost_percent).toFixed(2) + "%")
                }
                if (Number(settings.transaction_cost_pershare)>0.0){
                    if (first){
                        transCostString += " + "
                    }
                    else {
                        first = true;
                    }
                    transCostString += (Number(settings.transaction_cost_pershare).toFixed(2) + " per Share.")
                }
                if (!first){
                    transCostString = "Free";
                }
                setLeagueTransTypeString(transCostString);
            }
            else if (lg_format===1) {
                setMaxSharesPerTeam("No")
                if (settings.maxsharesperteam){
                    const maxteamshares = settings.maxsharesperteam;
                    if (maxteamshares===1){
                        setMaxSharesPerTeam("Yes")
                    }
                    else if (maxteamshares>1){
                        setMaxSharesPerTeam(String(maxteamshares))
                    }
                }

                setPlayoffMultipliers(settings.playoff_multipliers ?  "Yes" : "No");

                const response2 = await axiosPrivate.post('/league/getRosterSettings',dataSend);

                console.log(response2.data)
                const roster_data = new Map();

                for (const pos of response2.data){
                    roster_data.set(pos.position_id,pos.quantity);
                }

                console.log(roster_data)
                setRosterData(roster_data);
            }



        }
        catch (err){

        }

        setIsLoading(false);
      }, [axiosPrivate,props.league_id])
    
      useEffect( () => {
        fetchData();
      }, [fetchData]);

      return (
        <span>


            {!isLoading &&
                <div>
                    <br></br>League Name: {leagueName}
                    <br></br> League Creator: {leagueCreator}
                    <br></br> Privacy: {leaguePrivate}
                    {leaguePrivate==="Private" && <span>
                    <br></br> Password: {leaguePass}</span>}
                    <br></br> Sport: {leagueSport}
                    <br></br> Timeframe: {leagueTimeframe}
                    <br></br> League Format: {leagueFormatMap[leagueFormat]}
                    <br></br><br></br>

                    {leagueFormat===0 &&
                        <div> <b>Portfolio Format Settings:</b>
                            <br></br>Starting Budget: ${leagueBudget}
                            <br></br>Transaction Cost: {leagueTransTypeString}
                            <br></br>Shorting: {leagueShorting}
                            <br></br> In-Season Trading: {leagueInseason}
                        </div>
                    }
                    {leagueFormat===1 &&
                        <div> <b>Roster Format Settings:</b>
                            <br></br>Playoff Multipliers Active: {playoffMultipliers}
                            <br></br>Restricted shares per {leagueSport} Team: {maxSharesPerTeam}
                            <br></br>In-Season Roster Changes: {leagueInseason}
                            <br></br>Roster:
                            <br></br>    QB:   {rosterData.get(2)}
                            <br></br>    RB:   {rosterData.get(3)}
                            <br></br>    WR:   {rosterData.get(4)}
                            <br></br>    TE:   {rosterData.get(5)}
                            <br></br>    K:    {rosterData.get(6)}
                            <br></br>    TEAM: {rosterData.get(1)}
                            <br></br>
                            <br></br>Scoring:
                            <br></br>    Yards per Passing Point: 25
                            <br></br>    Points per Passing TD: 4
                            <br></br>    Points per Interception: -2
                            <br></br>    Yards per Rushing Point: 8
                            <br></br>    Points per Rushing TD: 6
                            <br></br>    Points per Reception: 1
                            <br></br>    Yards per Recieving Point: 10
                            <br></br>    Points per Recieving TD: 6
                            <br></br>    Points per Fumble Lost: -2
                            <br></br>    Points per Extra Point Made: 1
                            <br></br>    Points per 39 Yards or Fewer Field Goal Made: 3
                            <br></br>    Points per Field Goal Made 40-49 Yards: 4
                            <br></br>    Points per Field Goal Made 50-59 Yards: 5
                            <br></br>    Points per Field Goal Made 50-59 Yards: 6
                            <br></br>    Points per Extra Point or Field Goal under 40 Yards Missed: -1
                            <br></br>    TEAM Points per Win: 10
                        </div>
                    }


                </div>
            }
        </span>
      )

}


export default LeagueSettings;