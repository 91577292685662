import React, {useState } from "react";
import LeagueBody from "./LeagueBody";
import { Menu } from "antd";

const LeaguePage = (props) => {


    console.log(props);

  const [leagueChoice,setLeagueChoice] = useState("Home");
  

  const handleClick = (e) => {
    //console.log("click ", e);
    console.log("handle click");
    console.log(e.key);
    setLeagueChoice(e.key);
  };


  return (
    <div>
      <div>Current League: {props.leagueInfo.league_name}</div>
      <Menu
        onClick={handleClick}
        mode="horizontal"
        defaultSelectedKeys="Home"
      >
        <Menu.Item key="Home">League Home</Menu.Item>
        <Menu.Item key="Portfolio">My Ports</Menu.Item>
        <Menu.Item key="LeagueGraph">Performance</Menu.Item>
        <Menu.Item key="LeagueInvite">Invite</Menu.Item>
        <Menu.Item key="Settings">Settings</Menu.Item>
      </Menu>
      <LeagueBody table={leagueChoice} leagueInfo={props.leagueInfo} ></LeagueBody>
    </div>
  );
  
}

export default LeaguePage;
