import {Form, Table, Button} from "antd";
import React, { useEffect,useState, useCallback} from "react";
import useGetMyLeagues from "../hooks/useGetMyLeagues.js";
import { ARCHIVE_THRESHOLD, leagueFormatMap, timeframeToString } from "../CONSTANTS.js";
import { Link } from "react-router-dom";
//import axios from 'axios';


const MyLeaguePage = (props) => {

  const getMyLeagues = useGetMyLeagues();
  const [tableData,setTableData] = useState([{}]);

  const [archivedData,setArchivedData] = useState([]);

  const [isErr,setIsErr] = useState(false);
  const [errMsg,setErrMsg] = useState("");
  const [isSubmitting,setIsSubmitting] = useState(false);


  const getMyLeagueInfo = useCallback(async() => {
    try {
      setIsSubmitting(true);
      setIsErr(false)
      const data = await getMyLeagues();
      console.log(data);
      if (data.length>0){
        const currData = [];
        const archData = [];
        for (const league of data){
          if (league.timeframe>ARCHIVE_THRESHOLD){
            currData.push(league);
          }
          else {
            archData.push(league)
          }
        }
        setTableData(currData.sort( (a,b) => (b.timeframe - a.timeframe)));
        setArchivedData(archData.sort( (a,b) => (b.timeframe - a.timeframe)));
      }
      else {
        setTableData([{}]);
      }

      setIsSubmitting(false);
    }
    catch (err) {
      console.log(err);
      setErrMsg(err.message);
      setIsErr(true);
      setIsSubmitting(false);
      return err;
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    getMyLeagueInfo();
  }, [getMyLeagueInfo]); //empty dependency array means function only called once on mount


  const columns = [
    {
      title: "League Name",
      dataIndex: "league_name",
      key: "league_name",
      render : (text,record) => (
        <Link to={"/league/" + record.key}>{record.league_name}</Link>

      )
    },
    {
      title: "Season",
      dataIndex: "timeframe",
      render: (text,record) => (
        timeframeToString[record.timeframe]
      ),
    },
    {
      title: "Format",
      dataIndex: "lg_format",
      render: (text,record) => (
        leagueFormatMap[record.lg_format]
      ),
    }
  ]



  /*const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  } */
 

  return (

    <div>
      <span>
      {!isErr &&
      <span>
        <Table
          title = {() => "League Results"}
          dataSource={tableData}
          columns={columns}
          loading={isSubmitting}
          size="middle"
        />
        <Table
        title = {() => "Archived Leagues"}
        dataSource={archivedData}
        columns={columns}
        loading={isSubmitting}
        size="middle"
        />
      </span>
      }
      {isErr && <div>{errMsg}<p>Failed to retrieve your tables.</p>
      <Form>
        <Form.Item>
          <Button  onClick={getMyLeagueInfo} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
      {(tableData.length===0) && (archivedData.length===0) &&
    <span>
      <div>You are not part of any leagues. Please create or join one.</div>
        <Link to="/league/create">
            <Button>Create Leagues</Button>
        </Link>
    </span>}
      </span>
    </div>
  )
}

export default MyLeaguePage;