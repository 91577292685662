import useAxiosPrivate from "./hooks/useAxiosPrivate.js";
import React, { useState, useEffect, useCallback} from "react";
import { Button, Form, Input, Modal } from "antd";
import NotificationSettings from "./NotificationSettings.js";
import { VERSION } from "./CONSTANTS.js";
import axios from "./axios.js";


const ProfileInfo = (props) => {

  const axiosPrivate = useAxiosPrivate();

  const [emailVerificationStatus,setEmailVerificationStatus] = useState(props.profileData.email_verif);
  const [savedProfileData,setSavedProfileData] = useState(props.profileData);
  //const [currentProfileData,setCurrentProfileData] = useState(props.profileData);

  const [verificationWaiting,setVerificationWaiting] = useState(false);

  const [editing,setEditing] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [serverVer,setServerVer] = useState("");

  const  getServerVersion =  useCallback(async() => {


      const res = await axios.get('/version');
      //console.log(res)
      setServerVer(res.data);
  },[]);

  useEffect(() => {
    getServerVersion();
}, [getServerVersion]);

    useEffect(() => { 
        setSavedProfileData(props.profileData);
        setEmailVerificationStatus(props.profileData.email_verif);
    }, [props.profileData]);


    const getUserProfile = async () => {
        const response = await axiosPrivate.get('/user/getProfile')
        console.log(response);
        setSavedProfileData(response.data[0]);
        setEmailVerificationStatus(response.data[0].email_verif);
      }

    const requestEmailVerification = async() => {
        setVerificationWaiting(true);
        try {
    
          await axiosPrivate.post('/user/requestEmailVerification');
          setErrMsg("Token Generated");
        }
        catch (err) {
          console.log(err)
          if (err?.response?.data?.message !== "Token already sent.")
          {
            setErrMsg("Error generating email information. ")
            setVerificationWaiting(false);
          }
          else {
            setErrMsg("Token already generated");
          }


        }
      }
    
      const submitEmailVerification = async(e) => {
        try {
          console.log(e);
          await axiosPrivate.post('/user/verifyEmail',e);
          setErrMsg("");
          setSuccessMsg("Email Successfully verified.")
          getUserProfile();
        }
        catch (err) {
          setErrMsg("Email Verification failed.")
        }
      }

      const startEditing = () => {
        setErrMsg("");
        setSuccessMsg("");
        setEditing(true);
      }

      const onEditCancel = () => {
        setErrMsg("");
        setSuccessMsg("");
        setEditing(false);
      }

      const updateProfile = async(e) => {
        console.log(savedProfileData);
        console.log(e);
        let data = {
            payment_username: e.payment_username
        }
        try {
            await axiosPrivate.post('/user/updateProfile',data);
            setErrMsg("");
            setSuccessMsg("Profile Updated!")
            getUserProfile();
            setEditing(false);
        }
        catch (err) {
            setErrMsg("Error Updating Notification Preferences")
        }
      }


      return (
        <Modal
            title={editing ? "Edit Pofile":"Your Profile"}
            open={props.profileModalVisible}
            footer={null}
            onCancel={props.onCancel}>
            <div> Welcome {props.usrname}! </div>

            {editing ? 
                <Form onFinish={updateProfile} 
                initialValues={
                    {
                        "payment_username":savedProfileData.payment_username,
                        "fname":savedProfileData.fname,
                        "fav_team":savedProfileData.favorite_team
                    }}>
                    {/*<Form.Item label="First Name" name="fname">
                        <Input />
                    </Form.Item>*/}
                    <Form.Item label="Venmo" name="payment_username">
                        <Input />
                    </Form.Item>
                    {/*<Form.Item label="Favorite Team" name="fav_team">
                        <Input />
                    </Form.Item>*/}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="button" onClick={onEditCancel}>Cancel</Button>
                    </Form.Item>
                </Form>:
                <span>
                    <div>My Referral Code: {props.referralCode}</div>
                    <div>Name: {savedProfileData.fname} {savedProfileData.lname}</div>
                    {savedProfileData.payment_username ? <div>Venmo: {savedProfileData.payment_username}</div>: <span/>}
                    <div>
                        <Button onClick={startEditing}>Edit Profile</Button>
                    </div>
                    {emailVerificationStatus ?
                        <NotificationSettings profileData={savedProfileData}/>
                        :
                        <span>
                            <div>Email Verification</div>
                            <Button onClick={requestEmailVerification} disabled={verificationWaiting}>Send code to E-Mail</Button>
                            <Form onFinish={submitEmailVerification}>
                            <Form.Item label="Verification Code" name="token" rules={[{ required: true, message:'Verification Code required'  }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                            </Form>
                        </span>
                    }
                </span>  }

            <div color='red'>{successMsg}</div>
            <div color='red'>{errMsg}</div>
            <Button onClick={props.showChangePassword} disabled={editing}>Change Password</Button>
            <Button onClick={props.submitLogout} disabled={editing}>Logout</Button>
            <div>Current version: {VERSION}</div>
            <div>Server version: {serverVer}</div>
        </Modal>
      )

}


export default ProfileInfo;